import React, { Key, useContext } from "react";
import { BarStackHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Tooltip, useTooltip } from "@visx/tooltip";
import { ThemeContext } from "styled-components";
import { ToolTipLabel, tooltipStyles } from "./SurahReliance";
import { AxisLeft } from "@visx/axis";
import localPoint from "@visx/event/lib/localPoint";
import { SchoolName, schoolNames } from "../Types";

export type BarStackHorizontalProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  data: any;
};

const defaultMargin = { top: 16, left: 64, right: 64, bottom: 16 };

const DnaBar = ({
  width,
  height,
  margin = defaultMargin,
  data,
}: BarStackHorizontalProps) => {
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const themeContext = useContext(ThemeContext);

  const xScale = scaleLinear<number>({
    domain: [0, 100],
    nice: true,
  });

  const yScale = scaleBand<SchoolName>({
    domain: schoolNames,
    padding: 0.2,
  });

  const keys = [] as any;

  Array.from({ length: 114 }, (_x, i) => {
    keys.push((i + 1).toString());
  });

  const getColor = (key: Key, _: number): string => {
    return themeContext.components.dnaColors[key];
  };

  const getSchoolName = (d: any) => {
    return d.school;
  };

  xScale.rangeRound([0, xMax]);
  yScale.rangeRound([yMax, 0]);

  const primaryColor = themeContext.primaryColor;

  const handleMouseOverBar = (event: any, datum: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event);

    showTooltip({
      tooltipLeft: coords ? coords?.x - 210 : 0,
      tooltipTop: coords ? coords?.y - 50 : 0,
      tooltipData: datum,
    });
  };

  return width < 10 ? null : (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill={"#fff"} rx={14} />
        <Group top={margin.top} left={margin.left}>
          <BarStackHorizontal
            data={data}
            keys={keys}
            height={yMax}
            y={getSchoolName}
            xScale={xScale}
            yScale={yScale}
            color={getColor}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    width={bar.width}
                    height={bar.height}
                    fill={bar.color}
                    onMouseMove={(e) => handleMouseOverBar(e, bar)}
                    onMouseOut={() => hideTooltip()}
                  />
                ))
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            numTicks={5}
            stroke={primaryColor}
            tickStroke={primaryColor}
            tickLabelProps={() => ({
              fill: primaryColor,
              fontSize: 14,
              fontFamily: "Arial",
              textAnchor: "end",
              dy: "0.33em",
              fontWeight: 700,
            })}
          />
        </Group>
      </svg>
      {tooltipOpen ? (
        <Tooltip
          applyPositionStyle={false}
          left={tooltipLeft}
          top={tooltipTop}
          style={tooltipStyles}
        >
          <ToolTipLabel>Surah: {tooltipData.key}</ToolTipLabel>
          <ToolTipLabel>
            Percentage: {tooltipData.bar.data[tooltipData.key].toFixed(2)}%
          </ToolTipLabel>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default DnaBar;
