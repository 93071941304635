import React, { useContext } from "react";
import { BarStackHorizontal } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { withTooltip, Tooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { LegendOrdinal } from "@visx/legend";
import styled, { ThemeContext } from "styled-components";
import { ToolTipLabel, tooltipStyles } from "./SurahReliance";
import { schoolNames } from "../Types";
import { normalizeUnicodeText } from "normalize-unicode-text";

type TooltipData = {
  bar: SeriesPoint<any>;
  key: string;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

interface ITop50Entry {
  _id: string;
  surah_num: string;
  ayah_num: string;
  school: string;
  count: number;
  convertedSurahNum: number;
  convertedAyahNum: number;
  verse: string;
}

export type BarStackHorizontalProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  data: ITop50Entry[];
};

const LegendContainer = styled.section`
  .visx-legend-label {
    font-family: "Arial";
    font-size: 1rem;
  }
`;

export const background = "#fff";
const defaultMargin = { top: 64, left: 48, right: 64, bottom: 64 };

let tooltipTimeout: number;

export default withTooltip<BarStackHorizontalProps, TooltipData>(
  ({
    width,
    height,
    events = false,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
    data,
  }: BarStackHorizontalProps & WithTooltipProvidedProps<TooltipData>) => {
    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const themeContext = useContext(ThemeContext);

    const getCount = (verseId: string, school: string) => {
      const entry: any = data.find(
        (e: any) => e.school === school && e.verse === verseId
      );

      if (entry) {
        return entry.count;
      }

      return 0;
    };

    const verses = Array.from(
      new Set(data.map((e: { verse: string }) => e.verse))
    );

    const schoolKeys = schoolNames;

    const baseData = verses.map((v: string) => ({
      verseId: v,
      Shafii: 0,
      Hanafi: 0,
      Hanbali: 0,
      Jafari: 0,
      Maliki: 0,
      total: 0,
    }));

    const preparedData = baseData.map((b: any) => {
      return {
        verseId: b.verseId,
        Shāfiʿī: getCount(b.verseId, "Shāfiʿī"),
        Ḥanafī: getCount(b.verseId, "Ḥanafī"),
        Ḥanbalī: getCount(b.verseId, "Ḥanbalī"),
        Jaʿfarī: getCount(b.verseId, "Jaʿfarī"),
        Mālikī: getCount(b.verseId, "Mālikī"),
        total:
          getCount(b.verseId, "Shāfiʿī") +
          getCount(b.verseId, "Ḥanafī") +
          getCount(b.verseId, "Ḥanbalī") +
          getCount(b.verseId, "Jaʿfarī") +
          getCount(b.verseId, "Mālikī"),
      };
    });

    preparedData.sort((a, b) => a.total - b.total);

    const highestCount = Math.max(
      ...Array.from(
        new Set(preparedData.map((e: { total: number }) => e.total))
      )
    );

    const countScale = scaleLinear<number>({
      domain: [0, highestCount],
      nice: true,
    });

    const verseScale = scaleBand<string>({
      domain: preparedData.map((d) => d.verseId),
      padding: 0.2,
    });

    countScale.rangeRound([0, xMax]);
    verseScale.rangeRound([yMax, 0]);

    const schoolsColorScale = scaleOrdinal<string, string>({
      domain: schoolNames,
      range: ["#9b5de5", "#f15bb5", "#fee440", "#00bbf9", "#00f5d4"],
    });

    if (data.length === 0) {
      return null;
    }

    const primaryColor = themeContext.primaryColor;

    return width < 10 ? null : (
      <>
        <svg width={width} height={height}>
          <rect width={width} height={height} fill={background} rx={14} />
          <Group top={margin.top} left={margin.left}>
            <BarStackHorizontal<
              {
                verseId: string;
                Shāfiʿī: number;
                Ḥanafī: number;
                Ḥanbalī: number;
                Jaʿfarī: number;
                Mālikī: number;
              },
              string
            >
              data={preparedData}
              keys={schoolKeys}
              height={yMax}
              y={(d: any) => d.verseId}
              xScale={countScale}
              yScale={verseScale}
              color={schoolsColorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <rect
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      onClick={() => {
                        console.log(bar);
                        if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                      }}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const top = bar.y + margin.top;
                        const left = bar.x + bar.width + margin.left;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: top,
                          tooltipLeft: left,
                        });
                      }}
                    />
                  ))
                )
              }
            </BarStackHorizontal>
            <AxisLeft
              hideAxisLine
              hideTicks
              scale={verseScale}
              numTicks={50}
              stroke={primaryColor}
              tickStroke={primaryColor}
              tickLabelProps={() => ({
                fill: primaryColor,
                fontSize: 12,
                textAnchor: "end",
                dy: "0.33em",
                fontWeight: 700,
              })}
            />
            <AxisBottom
              top={yMax}
              label={"Discovered in num tags"}
              scale={countScale}
              stroke={primaryColor}
              tickStroke={primaryColor}
              tickLabelProps={() => ({
                fill: primaryColor,
                fontSize: 16,
                textAnchor: "middle",
              })}
              labelProps={{
                fill: primaryColor,
                fontSize: 16,
                textAnchor: "middle",
              }}
            />
          </Group>
        </svg>
        <LegendContainer>
          <div
            style={{
              position: "absolute",
              top: margin.top / 2 - 10,
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <LegendOrdinal
              scale={schoolsColorScale}
              direction="row"
              labelMargin="0 15px 0 0"
            />
          </div>
        </LegendContainer>
        {tooltipOpen && tooltipData && (
          <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div>
              <ToolTipLabel
                style={{ color: schoolsColorScale(tooltipData.key) }}
              >
                {normalizeUnicodeText(tooltipData.key)}
              </ToolTipLabel>
            </div>
            <div>
              <ToolTipLabel>
                Citations: {tooltipData.bar.data[tooltipData.key]}
              </ToolTipLabel>
            </div>
          </Tooltip>
        )}
      </>
    );
  }
);
