import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";

import Loader from "./Loader";

export const IslamicArtContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 3rem 2rem;
  margin: 1rem 0;
  min-height: 6rem;

  background-color: ${(props) => props.theme.secondaryColor};

  ${(props) => `
  background-image: linear-gradient(67.5deg, ${props.theme.secondaryColor} 10%, transparent 10%),
    linear-gradient(157.5deg, ${props.theme.secondaryColor} 10%, transparent 10%),
    linear-gradient(67.5deg, transparent 90%, ${props.theme.secondaryColor} 90%),
    linear-gradient(157.5deg, transparent 90%, ${props.theme.secondaryColor} 90%),
    linear-gradient(22.5deg,${props.theme.secondaryColor} 10%, transparent 10%),
    linear-gradient(112.5deg, ${props.theme.secondaryColor} 10%, transparent 10%),
    linear-gradient(22.5deg, transparent 90%, ${props.theme.secondaryColor} 90%),
    linear-gradient(112.5deg, transparent 90%, ${props.theme.secondaryColor} 90%),
    linear-gradient(
      22.5deg,
      transparent 33%,
      #ffffff 33%,
      #ffffff 36%,
      transparent 36%,
      transparent 64%,
      #ffffff 64%,
      #ffffff 67%,
      transparent 67%
    ),
    linear-gradient(
      -22.5deg,
      transparent 33%,
      #ffffff 33%,
      #ffffff 36%,
      transparent 36%,
      transparent 64%,
      #ffffff 64%,
      #ffffff 67%,
      transparent 67%
    ),
    linear-gradient(
      112.5deg,
      transparent 33%,
      #ffffff 33%,
      #ffffff 36%,
      transparent 36%,
      transparent 64%,
      #ffffff 64%,
      #ffffff 67%,
      transparent 67%
    ),
    linear-gradient(
      -112.5deg,
      transparent 33%,
      #ffffff 33%,
      #ffffff 36%,
      transparent 36%,
      transparent 64%,
      #ffffff 64%,
      #ffffff 67%,
      transparent 67%
    );

  background-size: 250px 250px;
  background-position: -100px 150px, -150px 150px, -150px 100px, -100px 100px,
    -150px 100px, -100px 100px, -100px 150px, -150px 150px, 0 0, 0 0, 0 0, 0 0;

    `};
`;

const QuranFrame = styled.div`
  font-family: "mequran";
  font-size: 1.9rem;
  display: flex;
  padding: 2rem;
  direction: rtl;
  word-spacing: 0.75rem;
  line-height: 2;
  text-align: right;
  max-width: 60rem;
  background-color: #fff;
  align-items: center;
  border: 1px solid ${(props) => props.theme.primaryColor};
  background-color: #ddc;
  border: solid 5vmin #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  position: relative;

  &:before {
    border-radius: 2px;
    bottom: -2vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
    content: "";
    left: -2vmin;
    position: absolute;
    right: -2vmin;
    top: -2vmin;
  }

  &:after {
    border-radius: 2px;
    bottom: -2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    content: "";
    left: -2.5vmin;
    position: absolute;
    right: -2.5vmin;
    top: -2.5vmin;
  }
`;

const QuranInfo = styled.div`
  font-family: "Lobster";
  font-size: 1.3rem;
  padding: 1rem 2rem;
  color: ${(props) => props.theme.primaryColor};
  background-color: #fff;
  text-align: center;
`;

const QuranTranslation = styled.p`
  margin: 2rem 1rem 1rem;
  padding: 1rem 2rem;
  color: ${(props) => props.theme.primaryColor};
  background-color: #fff;
  text-align: center;
  border-radius: 3px;
  line-height: 1.5;
  font-weight: 700;
  max-width: 40rem;
  font-style: italic;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuranDisplay = (props: { surah: String; ayah: String }) => {
  const [isLoading, setLoading] = useState(true);

  const [verse, setVerse] = useState({
    ayah_body: "",
    surah_num: "",
    ayah_num: "",
    translation: "",
  });

  useEffect(() => {
    axios
      .get(`/api/quran/${props.surah}/${props.ayah}`)
      .then((response) => {
        if (response.data) {
          setVerse(response.data);
        }

        setLoading(false);
      })
      .catch((_) =>
        toast.error(
          "Something went wrong with getting the translation of a Qur'an verse. Please try again later."
        )
      );
  }, [props.surah, props.ayah]);

  return (
    <>
      <ToastContainer />
      <IslamicArtContainer id="quran-verse">
        {isLoading ? (
          <Loader text="Loading Qur'an verse" />
        ) : (
          <FlexColumn>
            <QuranFrame>
              {verse.ayah_body.length > 0
                ? verse.ayah_body
                : "Incorrect verse number"}
            </QuranFrame>
            {verse.translation.length > 0 ? (
              <QuranTranslation>
                "{verse.translation}" (Yusuf Ali)
              </QuranTranslation>
            ) : null}
            {verse.surah_num.length > 0 ? (
              <QuranInfo>
                {verse.surah_num}:{verse.ayah_num}
              </QuranInfo>
            ) : null}
          </FlexColumn>
        )}
      </IslamicArtContainer>
    </>
  );
};

export default QuranDisplay;
