import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ThreeColumns from "./ThreeColumns";
import { H1, H2 } from "./Typography";
import StackedBars from "./StackedBars";
import SurahReliance from "./SurahReliance";
import DnaBars from "./DnaBars";
import RadarChart, { RadarTitle } from "./RadarChart";
import { normalizeUnicodeText } from "normalize-unicode-text";
import { FlexRow } from "./Flex";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { SchoolName } from "../Types";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Loader";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatH1 = styled(H1)`
  margin: 2rem 0 1rem;
`;
const StatH2 = styled(H2)`
  margin: 1rem 0;
`;

const SurahRelianceContainer = styled.div`
  max-width: 120rem;
  overflow: auto;
`;

export const SubInstruction = styled.h3`
  font-family: "Lobster";
  font-size: 1.3rem;
  color: ${(props) => props.theme.pentiaryColor};
  margin-bottom: 1rem;
`;

const Stats = () => {
  const [statData, setStatData] = useState({
    top10: {
      all: [],
      shafi: [],
      hanafi: [],
      hanbali: [],
      jafari: [],
      maliki: [],
    },
    sunniExclusive: [],
    exclusivelyJafari: [],
    exclusivelyHanafi: [],
    exclusivelyShafi: [],
    exclusivelyHanbali: [],
    exclusivelyMaliki: [],
    allSchools: [],
    dna: [],
    similarity: {},
    reliance: [],
    the50: [],
  } as any);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/graphs/statistics.json")
      .then((res) => {
        setStatData(res.data);
        setIsLoading(false);
      })
      .catch((_) => {
        toast.error(
          "Something went wrong with getting the statistics data. Please try again later."
        );
      });
  }, []);

  return (
    <ThreeColumns style={{ paddingBottom: "4rem" }}>
      <ToastContainer />
      <Container>
        <StatH1>Statistics</StatH1>
        <StatH2>50 most cited verses and their distribution</StatH2>
        {!isLoading ? (
          <FlexRow>
            <FlexColumn style={{ width: "100%", height: "60rem" }}>
              <ParentSize debounceTime={10}>
                {({ width, height }) => (
                  <StackedBars
                    width={width}
                    height={height}
                    data={statData.the50}
                  />
                )}
              </ParentSize>
            </FlexColumn>
          </FlexRow>
        ) : (
          <Loader text="Loading top50 cited verses" />
        )}
        <FlexRow>
          <FlexColumn>
            <StatH2>Surah citation</StatH2>
            <SubInstruction>(Scroll horizontally to see more)</SubInstruction>
            {!isLoading ? (
              <SurahRelianceContainer>
                <SurahReliance
                  width={5000}
                  height={400}
                  data={statData.reliance}
                />
              </SurahRelianceContainer>
            ) : (
              <Loader text="Loading citations chart" />
            )}
          </FlexColumn>
        </FlexRow>
        <StatH2>Surah "dna"</StatH2>
        <SubInstruction>(Percentages of all detected citations)</SubInstruction>
        {!isLoading ? (
          <FlexRow>
            <FlexColumn style={{ width: "100%", height: "25rem" }}>
              <ParentSize debounceTime={10}>
                {({ width, height }) => (
                  <DnaBars data={statData.dna} width={width} height={height} />
                )}
              </ParentSize>
            </FlexColumn>
          </FlexRow>
        ) : (
          <Loader text="Loading dna data" />
        )}
        <StatH2>Resemblance</StatH2>
        <SubInstruction>
          (Shorter distance, higher resemblance. Measured are the number and
          kinds of verses cited)
        </SubInstruction>
        {!isLoading ? (
          <FlexRow style={{ flexWrap: "wrap" }}>
            {Object.keys(statData.similarity).map(
              (school: string, index: number) => {
                return (
                  <FlexColumn
                    style={{
                      width: "50%",
                      boxSizing: "border-box",
                      height: "30rem",
                      paddingRight: index % 2 === 0 ? "1rem" : 0,
                      paddingBottom: "1rem",
                    }}
                  >
                    <RadarTitle>{normalizeUnicodeText(school)}</RadarTitle>
                    <ParentSize debounceTime={10}>
                      {({ width, height }) => (
                        <RadarChart
                          title={normalizeUnicodeText(school)}
                          width={width}
                          height={height}
                          data={statData.similarity[school as SchoolName]}
                        />
                      )}
                    </ParentSize>
                  </FlexColumn>
                );
              }
            )}
          </FlexRow>
        ) : (
          <Loader text="Loading resemblance radarcharts" />
        )}
      </Container>
    </ThreeColumns>
  );
};

export default Stats;
