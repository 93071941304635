import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import ThreeColumns from "./ThreeColumns";
import Button from "./Button";
import ReactSelect from "react-select";
import { toast, ToastContainer } from "react-toastify";

import {
  InputGroupContainer,
  InputLabel,
  InputSeparator,
  ReactSelectContainer,
  TextInput,
} from "./SelectContainer";
import { useParams } from "react-router-dom";
import { IBibliographyEntry } from "./IBibliographyEntry";
import { H1 } from "./Typography";
import useFetchCorpusResults from "./useFetchCorpusResults";
import { schoolNames } from "../Types";
import Cluster from "./Cluster";
import { SubInstruction } from "./Stats";
import { LinkButton } from "./Button";
declare const window: any;

const VerseSearchContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  max-width: 42rem;
`;

const PageTitle = styled(H1)`
  margin: 2rem 0;
`;

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;

  ${PageTitle} {
    margin-bottom: 1rem;
  }
`;

const SearchVerse = () => {
  const { surah_num, ayah_num }: any = useParams();
  const [bibliography, setBibliography] = useState([]);
  const [coCitation, setCoCitation] = useState("");

  const themeContext = useContext(ThemeContext);

  const [form, setFormData] = useState({
    surahNum: "",
    ayahNum: "",
  });

  const formRef = useRef<HTMLFormElement>(null);

  const [
    quranDisplay,
    verseMetaInfo,
    relevantPages,
    pagination,
    verse,
    setVerse,
    filters,
    setFilters,
    paginationData,
  ] = useFetchCorpusResults();

  useEffect(() => {
    axios
      .get("/api/titles")
      .then((response) => {
        setBibliography(response.data.docs);
      })
      .catch((_) => {
        toast.error(
          "Something went wrong with getting the titles. Try again later."
        );
      });
  }, []);

  useEffect(() => {
    if (surah_num && ayah_num) {
      setVerse({
        surah_num,
        ayah_num,
      });
    }
  }, [surah_num, ayah_num, setVerse]);

  const onSubmitVerseSearch = (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget as HTMLFormElement);

    setVerse({
      surah_num: formData.get("surah_num") as string,
      ayah_num: formData.get("ayah_num") as string,
    });

    const schools = formData.get("schools") as string;
    const periods = formData.get("periods") as string;
    const authors = formData.get("authors") as string;

    window.gtag("event", "search", {
      search_term: `${formData.get("surah_num") as string}:${
        formData.get("ayah_num") as string
      } - ${schools} - ${periods} - ${authors}`,
    });
  };

  const styles = {
    container: (base: any) => ({
      ...base,
      flex: "1 1 auto",
    }),
    control: (base: any) => ({
      ...base,
      borderRadius: "0",
      border: `1px solid ${themeContext.primaryColor}`,
      borderColor: themeContext.primaryColor,
      boxShadow: "none",
      outline: 0,
    }),
    menu: (base: any) => ({
      ...base,
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      fontFamily: "Arial",
      padding: "0.4rem 0",
    }),
    option: (base: any) => ({
      ...base,
      fontFamily: "Arial",
    }),
  };

  const setLinkedVerse = (label: string, links: any) => {
    setCoCitation(label);

    setFilters({
      ...filters,
      link: links,
    });
  };

  return (
    <FlexColumnDiv>
      <ToastContainer />
      <ThreeColumns style={{ overflow: "visible" }}>
        <FlexColumnDiv>
          <PageTitle>Search</PageTitle>
          <VerseSearchContainer>
            <FlexForm onSubmit={onSubmitVerseSearch} ref={formRef}>
              <FlexRow>
                <InputGroupContainer>
                  <InputLabel htmlFor="type">Surah number</InputLabel>
                  <TextInput
                    type="number"
                    name="surah_num"
                    max={114}
                    onChange={(e) => {
                      setFormData({
                        ayahNum: form.ayahNum,
                        surahNum: e.currentTarget.value,
                      });
                    }}
                  />
                </InputGroupContainer>
                <InputSeparator />
                <InputGroupContainer>
                  <InputLabel htmlFor="type">Ayah number</InputLabel>
                  <TextInput
                    type="number"
                    name="ayah_num"
                    max={286}
                    onChange={(e) => {
                      setFormData({
                        ayahNum: e.currentTarget.value,
                        surahNum: form.surahNum,
                      });
                    }}
                  />
                </InputGroupContainer>
              </FlexRow>
              <FlexRow>
                <ReactSelectContainer>
                  <InputLabel>Authors:</InputLabel>

                  <ReactSelect
                    name="authors"
                    delimiter=","
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(e: any) => {
                      if (!e) {
                        setFilters({
                          ...filters,
                          authors: [],
                        });

                        return;
                      }

                      const values = e.map((val: any) => val["value"]);

                      setFilters({
                        ...filters,
                        authors: values,
                      });
                    }}
                    options={bibliography.map((entry: IBibliographyEntry) => {
                      return { label: entry["author"], value: entry["author"] };
                    })}
                    styles={styles}
                  />
                </ReactSelectContainer>
              </FlexRow>
              <FlexRow>
                <ReactSelectContainer>
                  <InputLabel>Schools:</InputLabel>

                  <ReactSelect
                    name="schools"
                    delimiter=","
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(e: any) => {
                      if (!e) {
                        setFilters({
                          ...filters,
                          schools: [],
                        });

                        return;
                      }

                      const values = e.map((val: any) => val["value"]);

                      setFilters({
                        ...filters,
                        schools: values,
                      });
                    }}
                    options={schoolNames.map((schoolName) => {
                      return {
                        value: schoolName,
                        label: schoolName,
                      };
                    })}
                    styles={styles}
                  />
                </ReactSelectContainer>
              </FlexRow>
              <FlexRow>
                <ReactSelectContainer>
                  <InputLabel>Periods:</InputLabel>

                  <ReactSelect
                    name="periods"
                    delimiter=","
                    styles={styles}
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(e: any) => {
                      if (!e) {
                        setFilters({
                          ...filters,
                          periods: [],
                        });

                        return;
                      }

                      const values = e.map((val: any) => val["value"]);

                      setFilters({
                        ...filters,
                        periods: values,
                      });
                    }}
                    options={[
                      { value: "formative", label: "formative" },
                      { value: "early classical", label: "early classical" },
                      { value: "late classical", label: "late classical" },
                      {
                        value: "early postclassical",
                        label: "early postclassical",
                      },
                      {
                        value: "late postclassical",
                        label: "late postclassical",
                      },
                    ]}
                  />
                </ReactSelectContainer>
              </FlexRow>
              <FlexRow>
                <InputGroupContainer>
                  <Button
                    disabled={
                      form.surahNum.length === 0 || form.ayahNum.length === 0
                    }
                  >
                    Search
                  </Button>
                </InputGroupContainer>
              </FlexRow>
            </FlexForm>
          </VerseSearchContainer>
        </FlexColumnDiv>
      </ThreeColumns>
      {quranDisplay}
      {relevantPages() ? verseMetaInfo : null}
      {verse.surah_num !== "" ? (
        <Cluster
          key={`Cluster-for-${verse.surah_num}-${verse.ayah_num}`}
          surahNum={verse.surah_num}
          ayahNum={verse.ayah_num}
          setLinkedVerse={setLinkedVerse}
        />
      ) : null}
      {relevantPages() ? (
        <>
          {!filters.link || filters.link.length === 0 ? (
            <H1 id="corpus-header" style={{ margin: "3rem 0 0" }}>
              {"Corpus results"}
            </H1>
          ) : null}
          {filters.link && filters.link.length > 0 ? (
            <>
              <H1 id="corpus-header" style={{ margin: "3rem 0 0" }}>
                {"Co-citation corpus results"}
              </H1>
              <SubInstruction
                style={{ textAlign: "center", margin: "1rem 0 0" }}
              >
                {`( ${verse.surah_num}:${verse.ayah_num} linked to ${coCitation} )`}
              </SubInstruction>
              <LinkButton
                onClick={() => {
                  const formData = new FormData(
                    formRef.current as HTMLFormElement
                  );

                  const schools = formData.get("schools") as string;
                  const periods = formData.get("periods") as string;
                  const authors = formData.get("authors") as string;

                  let newFilters = {
                    schools:
                      schools.split(",")[0] !== "" ? schools.split(",") : [],
                    periods:
                      periods.split(",")[0] !== "" ? periods.split(",") : [],
                    authors:
                      authors.split(",")[0] !== "" ? authors.split(",") : [],
                  };

                  setCoCitation("");
                  setFilters({
                    ...newFilters,
                    link: [] as string[],
                  });
                }}
              >
                {"< Return to regular results"}
              </LinkButton>
            </>
          ) : null}
          <SubInstruction
            style={{
              textAlign: "center",
              margin: "1.3rem 0",
              fontSize: "1.6rem",
            }}
          >
            Page {paginationData.page + 1} of {paginationData.totalPages}{" "}
          </SubInstruction>
        </>
      ) : null}

      {!relevantPages() && verse.surah_num !== "" ? (
        <H1 id="corpus-header" style={{ margin: "3rem 0" }}>
          {"No results found for this verse"}
        </H1>
      ) : null}
      <ThreeColumns>{relevantPages()}</ThreeColumns>
      {pagination}
    </FlexColumnDiv>
  );
};

export default SearchVerse;
