import React from "react";
import styled, { ThemeProvider } from "styled-components";
import MainPage from "./Components/MainPage";
import GlobalStyle from "./globalStyle";
import "react-toastify/dist/ReactToastify.css";

import theme from "./theme.json";

const MinRequirements = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.secondaryColor};
  z-index: 10;
  overflow-y: auto;

  p {
    color: ${(props) => props.theme.primaryColor};
    padding: 3rem;
    font-size: 2rem;
    line-height: 1.5;
  }
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div className="App">
        <MinRequirements>
          <p>
            Due to the complex nature of various UI elements, this webpage
            requires a minimum screen size of 768px (e.g. an IPad or bigger).
          </p>
        </MinRequirements>
        <MainPage />
      </div>
    </ThemeProvider>
  );
}

export default App;
