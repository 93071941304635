import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { darken } from "polished";

const ButtonStyled = styled.button`
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.secondaryColor};
  font-size: 1.4rem;
  border: 0;
  padding: 0.25rem 1rem;

  &:disabled {
    background-color: #d1d1d1;

    &:hover {
      cursor: not-allowed;
      background-color: #d1d1d1;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${darken(0.12, "#fed7bf")};
  }
`;

const SecondaryButtonStyled = styled.button`
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor};
  font-size: 1.4rem;
  border: 0;
  padding: 0.25rem 1rem;
  background: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

interface IButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: FunctionComponent<IButtonProps> = (props) => {
  return (
    <ButtonStyled
      onClick={props.onClick}
      type="submit"
      disabled={props.disabled}
    >
      {props.children}
    </ButtonStyled>
  );
};

const SecondaryButton: FunctionComponent<IButtonProps> = (props) => {
  return (
    <SecondaryButtonStyled onClick={props.onClick} type="submit">
      {props.children}
    </SecondaryButtonStyled>
  );
};

const LinkButton = styled.button`
  font-size: 1rem;
  font-family: "Open Sans";
  margin: 1rem;
  border: 0;
  background: none;
  color: ${(props) => props.theme.components.link.default};

  &:active {
    color: ${(props) => props.theme.components.link.active};
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.components.link.hover};
  }
`;

export default Button;
export { SecondaryButton, LinkButton };
