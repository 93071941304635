import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ThreeColumns from "./ThreeColumns";
import { H1, H2 } from "./Typography";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Loader";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StatTitle = styled.h2`
  font-size: 1.5rem;
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor};
  margin: 1rem 0;
`;

const RowOfStats = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 1rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  max-height: 21rem;
  overflow-y: auto;
  margin-right: 3rem;
  border: 1.6rem solid ${(props) => props.theme.sistiaryColor};
  border-image: ${(props) =>
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23${props.theme.sistiaryColor.replace(
        "#",
        ""
      )}' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23${props.theme.sistiaryColor.replace(
        "#",
        ""
      )}'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")`}
    25;
`;

const Table = styled.table`
  width: 100%;
  td {
    padding: 0.4rem;
    border-right: 1px solid ${(props) => props.theme.primaryColor};
    border-bottom: 1px solid ${(props) => props.theme.primaryColor};
  }

  tr:first-child {
    td {
      font-weight: 700;
      color: #6e6d6d;
    }
  }
  tr {
    td:last-child {
      border-right: 0;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
`;

const StatH1 = styled(H1)`
  margin: 2rem 0 1rem;
`;
const StatH2 = styled(H2)`
  margin: 1rem 0;
`;

export const SubInstruction = styled.h3`
  font-family: "Lobster";
  font-size: 1.3rem;
  color: ${(props) => props.theme.pentiaryColor};
  margin-bottom: 1rem;
`;

const Rankings = () => {
  const [statData, setStatData] = useState({
    top10: {
      all: [],
      shafi: [],
      hanafi: [],
      hanbali: [],
      jafari: [],
      maliki: [],
    },
    sunniExclusive: [],
    exclusivelyJafari: [],
    exclusivelyHanafi: [],
    exclusivelyShafi: [],
    exclusivelyHanbali: [],
    exclusivelyMaliki: [],
    allSchools: [],
    dna: [],
    similarity: {},
    reliance: [],
    the50: [],
  } as any);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/graphs/statistics.json")
      .then((res) => {
        setStatData(res.data);
        setIsLoading(false);
      })
      .catch((_) => {
        toast.error(
          "Something went wrong with getting the statistics data. Please try again later."
        );
      });
  }, []);

  return (
    <ThreeColumns style={{ paddingBottom: "4rem" }}>
      <ToastContainer />
      <Container>
        <StatH1>Statistics</StatH1>
        <StatH2>Top 10</StatH2>
        {!isLoading ? (
          <>
            <RowOfStats>
              <FlexColumn>
                <StatTitle>All schools</StatTitle>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Count</td>
                        <td>Presence in works</td>
                      </tr>
                      {statData.top10.all.map((entry: any, i: number) => (
                        <tr key={`all-schools-${i}-row`}>
                          <td>{i + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry._id.surah_num}/${entry._id.ayah_num}`}
                            >
                              {entry._id.surah_num}:{entry._id.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.count}</td>
                          <td>{entry.titles.length} / 55</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatTitle>Shafiʿi school</StatTitle>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Count</td>
                        <td>Presence in works</td>
                      </tr>
                      {statData.top10.shafi.map((entry: any, i: number) => (
                        <tr key={`shafii-${i}-row`}>
                          <td>{i + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry._id.surah_num}/${entry._id.ayah_num}`}
                            >
                              {entry._id.surah_num}:{entry._id.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.count}</td>
                          <td>{entry.titles.length} / 11</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatTitle>Hanafi school</StatTitle>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Count</td>
                        <td>Presence in works</td>
                      </tr>
                      {statData.top10.hanafi.map((entry: any, i: number) => (
                        <tr key={`hanafi-${i}-row`}>
                          <td>{i + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry._id.surah_num}/${entry._id.ayah_num}`}
                            >
                              {entry._id.surah_num}:{entry._id.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.count}</td>
                          <td>{entry.titles.length} / 11</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatTitle>Hanbali school</StatTitle>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Count</td>
                        <td>Presence in works</td>
                      </tr>
                      {statData.top10.hanbali.map((entry: any, i: number) => (
                        <tr key={`hanbali-${i}-row`}>
                          <td>{i + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry._id.surah_num}/${entry._id.ayah_num}`}
                            >
                              {entry._id.surah_num}:{entry._id.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.count}</td>
                          <td>{entry.titles.length} / 11</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatTitle>Jaʿfari school</StatTitle>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Count</td>
                        <td>Presence in works</td>
                      </tr>
                      {statData.top10.jafari.map((entry: any, i: number) => (
                        <tr key={`jafari-${i}-row`}>
                          <td>{i + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry._id.surah_num}/${entry._id.ayah_num}`}
                            >
                              {entry._id.surah_num}:{entry._id.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.count}</td>
                          <td>{entry.titles.length} / 11</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatTitle>Maliki school</StatTitle>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Count</td>
                        <td>Presence in works</td>
                      </tr>
                      {statData.top10.maliki.map((entry: any, i: number) => (
                        <tr key={`maliki-${i}-row`}>
                          <td>{i + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry._id.surah_num}/${entry._id.ayah_num}`}
                            >
                              {entry._id.surah_num}:{entry._id.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.count}</td>
                          <td>{entry.titles.length} / 11</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
            </RowOfStats>
            <RowOfStats>
              <FlexColumn>
                <StatH2>Shared by all schools</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.allSchools.map((entry: any, index: number) => (
                        <tr key={`shared-all-schools-${index}-row`}>
                          <td>{index + 1}</td>
                          <td>
                            <Link
                              to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                            >
                              {entry.surah_num}:{entry.ayah_num}
                            </Link>
                          </td>
                          <td>{entry.authors_count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
            </RowOfStats>
            <RowOfStats>
              <FlexColumn>
                <StatH2>Exclusively Sunni</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.sunniExclusive.map(
                        (entry: any, index: number) => (
                          <tr key={`exclusive-sunni-${index}-row`}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                              >
                                {entry.surah_num}:{entry.ayah_num}
                              </Link>
                            </td>
                            <td>{entry.authors_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatH2>Exclusively Shia</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.exclusivelyJafari.map(
                        (entry: any, index: number) => (
                          <tr key={`exclusive-jafari-${index}-row`}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                              >
                                {entry.surah_num}:{entry.ayah_num}
                              </Link>
                            </td>
                            <td>{entry.authors_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatH2>Exclusively Hanafi</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.exclusivelyHanafi.map(
                        (entry: any, index: number) => (
                          <tr key={`exclusive-hanafi-${index}-row`}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                              >
                                {entry.surah_num}:{entry.ayah_num}
                              </Link>
                            </td>
                            <td>{entry.authors_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatH2>Exclusively Hanbali</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.exclusivelyHanbali.map(
                        (entry: any, index: number) => (
                          <tr key={`exclusive-hanbali-${index}-row`}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                              >
                                {entry.surah_num}:{entry.ayah_num}
                              </Link>
                            </td>
                            <td>{entry.authors_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatH2>Exclusively Maliki</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.exclusivelyMaliki.map(
                        (entry: any, index: number) => (
                          <tr key={`exclusive-maliki-${index}-row`}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                              >
                                {entry.surah_num}:{entry.ayah_num}
                              </Link>
                            </td>
                            <td>{entry.authors_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
              <FlexColumn>
                <StatH2>Exclusively Shafi</StatH2>
                <TableContainer>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nr.</td>
                        <td>Verse</td>
                        <td>Num authors</td>
                      </tr>
                      {statData.exclusivelyShafi.map(
                        (entry: any, index: number) => (
                          <tr key={`exclusive-shafii-${index}-row`}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/search/${entry.surah_num}/${entry.ayah_num}`}
                              >
                                {entry.surah_num}:{entry.ayah_num}
                              </Link>
                            </td>
                            <td>{entry.authors_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </FlexColumn>
            </RowOfStats>
          </>
        ) : (
          <Loader text="Loading tables" />
        )}
      </Container>
    </ThreeColumns>
  );
};

export default Rankings;
