import React from "react";

const ZoomOut = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <title>zoom-out</title>
      <path d="M26 22h-10c-0.552 0-1 0.448-1 1s0.448 1 1 1h10c0.552 0 1-0.448 1-1s-0.448-1-1-1z"></path>
      <path d="M38.762 31.912l-1.898-1.972-2.572-2.574c0.454-1.376 0.708-2.84 0.708-4.366 0-7.718-6.28-14-14-14s-14 6.282-14 14 6.28 14 14 14c1.526 0 2.992-0.254 4.368-0.708l4.644 4.64 0.13 0.11c1.202 1.012 2.696 1.568 4.208 1.568 3.452 0 6.26-2.808 6.26-6.262 0-1.68-0.656-3.256-1.848-4.436zM11 23c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10z"></path>
    </svg>
  );
};

export default ZoomOut;
