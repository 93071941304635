import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  background-color: ${(props) => props.theme.primaryColor};
  z-index: 3;
  font-size: 1rem;
  width: auto;
  position: fixed;
  padding: 0.5rem 1rem;
  bottom: 0;
  right: 0;

  h2 {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }

  li {
    margin: 0.2rem;

    a {
      outline: none;
      cursor: pointer;

      color: #fff;

      &:active: {
        color: #fff;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .pagination {
    flex-direction: row;
    display: flex;
  }

  .active {
    border: 1px solid ${(props) => props.theme.secondaryColor};
    padding: 0.2rem;
    border-radius: 3px;
    user-select: none;
  }
`;

export default PaginationContainer;
