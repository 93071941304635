import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import Sticky from "react-sticky-el";
import { Sigma, EdgeShapes } from "react-sigma";
import styled, { ThemeContext } from "styled-components";
import { normalizeUnicodeText } from "normalize-unicode-text";
import { toast, ToastContainer } from "react-toastify";
import SigmaLoader from "./SigmaLoader";
import SigmaControls from "./SigmaControls";
import { H1, H2 } from "./Typography";

import bibliography from "../data/bibliography.json";
import QuranDisplay from "./QuranDisplay";
import Reader from "./Reader";
import ThreeColumns from "./ThreeColumns";
import Loader from "./Loader";
import VerseMetaInfo, { MetaRow } from "./VerseMetaInfo";
import BookInfo from "./BookInfo";
import PaginationContainer from "./PaginationContainer";
import { FlexColumn, FlexRow } from "./Flex";
import { setDefaultNodeConfiguration } from "../utils/network";

import debounce from "lodash/debounce";
import { schoolNames } from "../Types";
import Cluster, { GraphHelp, InfoIcon } from "./Cluster";
import { LinkButton } from "./Button";
import { SubInstruction } from "./Stats";

const PageTitle = styled(H1)`
  margin: 2rem 0;
`;

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${PageTitle} {
    margin-bottom: 0.5rem;
  } ;
`;

const BaseFlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const NetworkContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Instruction = styled.p`
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor};
  padding: 1rem 0;
  font-size: 1.4rem;
  text-align: center;
`;

const SigmaContainer = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  border-bottom: 0;
`;

const VisualisationControls = styled.div`
  max-width: 14rem;
  line-height: 1.4;
`;

const VisualisationControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  justify-content: center;

  ${VisualisationControls} {
    margin: 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const VisualisationControlsHeader = styled.h2`
  font-size: 1.5rem;
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor};
  margin: 0 0 0.75rem;
`;

const VisualisationControlsUl = styled.ul`
  padding: 0.2rem;

  li > input:disabled ~ label {
    color: #dfe0df;
  }

  border: 1.6rem solid ${(props) => props.theme.sistiaryColor};
  border-image: ${(props) =>
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23${props.theme.sistiaryColor.replace(
        "#",
        ""
      )}' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23${props.theme.sistiaryColor.replace(
        "#",
        ""
      )}'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")`}
    25;
`;

const VisualisationControlsUlAlternate = styled(VisualisationControlsUl)`
  padding-top: 0.25rem;
`;

const VisualisationControlsSeparator = styled.li`
  margin: 0.25rem 0;
  text-align: center;
  color: ${(props) => props.theme.primaryColor};
`;

const VisualisationControlsLabel = styled.label`
  font-family: "Arial";
  margin-left: 0.5rem;

  span {
    font-size: 0.9rem;
  }
`;

export const Legend = styled.ul`
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  color: #000;
  background-color: #fff;
  padding: 1rem;
`;

export const LegendEntry = styled.li`
  display: flex;
  margin-right: 1rem;
  font-family: "Arial";
  align-items: center;
`;

export const LegendColorBlock = styled.span`
  background-color: ${(props) => props.color};
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 1rem;
`;

const AuthorsForm = styled.form`
  display: flex;
`;

const CustomMetaRow = styled(MetaRow)`
  background: #e2e2e2;
  padding: 0.5rem;
  justify-content: center;
  margin: 0 0 1.5rem;

  ${H2} {
    font-size: 1.4rem;
  }
`;

const intersect = (a, b) => {
  var setB = new Set(b);
  return [...new Set(a)].filter((x) => setB.has(x));
};

const SigmaNetwork = () => {
  const [selectedNode, setSelectedNode] = useState(undefined);

  const [allData, setAllData] = useState({});
  const [downloadedData, setDownloadedData] = useState({});

  const [schools, setSchools] = useState([]);

  const visualizeControlRef = useRef(null);
  const constraintsControlRef = useRef(null);
  const schoolsControlRef = useRef(null);
  const periodsControlRef = useRef(null);
  const authorsControlRef = useRef(null);

  const [visualizeControl, setVisualizeControl] = useState("");
  const [constraintControl, setConstraintControl] = useState("");
  const [schoolsControl, setSchoolsControl] = useState([]);
  const [periodsControl, setPeriodsControl] = useState([]);
  const [authorsControl, setAuthorsControl] = useState([]);
  const [links, setLinks] = useState([]);

  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalDocs: 0,
  });

  const [relevantPages, setRelevantPages] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [numVerses, setNumVerses] = useState(0);
  const [numAuthors, setNumAuthors] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [coCitation, setCoCitation] = useState("");

  const theme = useContext(ThemeContext);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get("/graphs/all.json", { cancelToken: source.token })
      .then((response) => {
        setDownloadedData({
          ...response.data,
          nodes: setDefaultNodeConfiguration(
            response.data.nodes,
            theme.components.legend.schools
          ),
        });
      })
      .catch((err) => {
        if (err.constructor.name === "Cancel") {
          console.log("Cancelling");
          return;
        }

        toast.error(
          "Something went wrong with getting the network data. Please try again later"
        );
      });
  }, [theme.components.legend.schools]);

  useEffect(() => {
    if (authorsControlRef.current) {
      const inputs = authorsControlRef.current.querySelectorAll("input");

      inputs.forEach((input) => {
        if (input.disabled) {
          input.checked = false;
        }
        return input;
      });
    }
  }, [schoolsControl, periodsControl]);

  useEffect(() => {
    const schoolsSelection = new FormData(schoolsControlRef.current).getAll(
      "schools[]"
    );

    const authorsSelection = new FormData(authorsControlRef.current).getAll(
      "authors[]"
    );

    const periodsSelection = new FormData(periodsControlRef.current).getAll(
      "periods[]"
    );

    const visualizeSelection = new FormData(visualizeControlRef.current).get(
      "visualize"
    );

    const constraintSelection = new FormData(constraintsControlRef.current).get(
      "constraints"
    );

    const newData = Object.assign({}, downloadedData);

    if (
      !(Object.keys(newData).length === 0 && newData.constructor === Object)
    ) {
      newData.nodes.map((node) => {
        node.color = theme.components.legend.unselected;

        if (constraintSelection === "core") {
          if (node.attributes.type === "aya") {
            const nodeSchools = node.attributes.schools.split(";");
            if (schools.every((v) => nodeSchools.includes(v))) {
              node.color = theme.components.legend.selected;
            }
          }
        }

        if (
          authorsSelection.length > 0 &&
          constraintSelection === "selectedauthors"
        ) {
          if (node.attributes.type === "book") {
            if (authorsSelection.includes(node.attributes.author)) {
              node.color =
                theme.components.legend.schools[node.attributes.group];
            }
          }

          if (node.attributes.type === "aya") {
            const nodeAuthors = node.attributes.authors.split(";");
            if (authorsSelection.every((v) => nodeAuthors.includes(v))) {
              node.color = theme.components.legend.selected;
            }
          }
        } else {
          if (node.attributes.type === "aya") {
            const authors = node.attributes.authors.split(";");

            if (constraintSelection === "none") {
            }
            if (constraintSelection === "filter") {
              authorsSelection.map((selectedAuthor) => {
                if (authors.length === 1 && authors.includes(selectedAuthor)) {
                  node.color = theme.components.legend.selected;
                }
                return selectedAuthor;
              });
            }
          }
        }

        if (constraintSelection === "filter") {
          if (authorsSelection.length > 0) {
            if (node.attributes.type === "aya") {
              const authors = node.attributes.authors.split(";");
              authorsSelection.map((selectedAuthor) => {
                if (authors.length === 1 && authors.includes(selectedAuthor)) {
                  node.color = theme.components.legend.selected;
                }
                return selectedAuthor;
              });
            }
          } else {
            if (node.attributes.type === "book") {
              if (
                schoolsSelection.includes(node.attributes.group) &&
                periodsSelection.includes(node.attributes.periods)
              ) {
                node.color =
                  theme.components.legend.schools[node.attributes.group];
              }
            }
            if (node.attributes.type === "aya") {
              const periods = node.attributes.periods.split(";");
              const schools = node.attributes.schools.split(";");

              schoolsSelection.map((school) => {
                if (schools.length === 1 && schools[0] === school) {
                  periodsSelection.map((period) => {
                    if (periods.length === 1 && periods[0] === period) {
                      node.color = theme.components.legend.selected;
                    }

                    return period;
                  });
                }
                return school;
              });
            }
          }
        }

        if (constraintSelection === "none") {
          if (authorsSelection.length > 0) {
            if (
              node.attributes.type === "book" &&
              authorsSelection.includes(node.attributes.author)
            ) {
              node.color =
                theme.components.legend.schools[node.attributes.group];
            }
            if (node.attributes.type === "aya") {
              const authors = node.attributes.authors.split(";");

              authorsSelection.map((selectedAuthor) => {
                if (authors.includes(selectedAuthor)) {
                  node.color = theme.components.legend.selected;
                }
                return selectedAuthor;
              });
            }
          } else {
            if (node.attributes.type === "book") {
              if (
                schoolsSelection.includes(node.attributes.group) &&
                periodsSelection.includes(node.attributes.periods)
              ) {
                node.color =
                  theme.components.legend.schools[node.attributes.group];
              }
            }

            if (node.attributes.type === "aya") {
              const periods = node.attributes.periods.split(";");
              const schools = node.attributes.schools.split(";");

              if (
                periods.some((r) => periodsSelection.includes(r)) &&
                schools.some((r) => schoolsSelection.includes(r))
              ) {
                node.color = theme.components.legend.selected;
              }
            }
          }
        }
      });

      if (visualizeSelection === "simplify") {
        const unselectedNodes = newData.nodes
          .filter((node) => node.color === theme.components.legend.unselected)
          .map((n) => n.id);

        const selectedNodes = newData.nodes
          .filter((node) => node.color !== theme.components.legend.unselected)
          .map((n) => n.id);

        if (selectedNodes.length < unselectedNodes.length) {
          newData.edges = newData.edges.filter((e) =>
            selectedNodes.includes(e.source)
          );

          newData.nodes = newData.nodes.filter((n) =>
            selectedNodes.includes(n.id)
          );
        } else {
          newData.edges = newData.edges.filter(
            (e) => !unselectedNodes.includes(e.source)
          );

          newData.nodes = newData.nodes.filter(
            (n) => !unselectedNodes.includes(n.id)
          );
        }

        const colorData = {};

        newData.nodes
          .filter((node) => node.attributes.type === "book")
          .map((node) => (colorData[node.attributes.author] = node.color));

        newData.nodes
          .filter((node) => node.attributes.type === "aya")
          .map((node) => {
            const authors = node.attributes.authors.split(";");

            const intersection = intersect(Object.keys(colorData), authors);

            if (intersection.length === 1) {
              node.color = colorData[intersection[0]];
              node.size = 1;
            }

            if (intersection.length > 1) {
              node.color = theme.components.legend.intersected;
            }

            return node;
          });
      }

      setNumVerses(
        newData.nodes.filter(
          (node) =>
            node.color !== theme.components.legend.unselected &&
            node.attributes.type === "aya"
        ).length
      );

      setNumAuthors(
        newData.nodes.filter(
          (node) =>
            node.color !== theme.components.legend.unselected &&
            node.attributes.type === "book"
        ).length
      );
    }

    setAllData(newData);
    setLoading(false);
  }, [
    schoolsControl,
    periodsControl,
    authorsControl,
    constraintControl,
    visualizeControl,
    downloadedData,
    schools,
    theme.components.legend.schools,
    theme.components.legend.unselected,
    theme.components.legend.selected,
    theme.components.legend.intersected,
  ]);

  const setControls = () => {
    setLoading(true);

    if (visualizeControlRef.current) {
      const formData = new FormData(visualizeControlRef.current);

      setVisualizeControl(formData.get("visualize"));
    }

    if (constraintsControlRef.current) {
      const formData = new FormData(constraintsControlRef.current);

      setConstraintControl(formData.get("constraints"));
    }

    if (authorsControlRef.current) {
      const formData = new FormData(authorsControlRef.current);

      setAuthorsControl(formData.getAll("authors[]"));
    }

    if (schoolsControlRef.current) {
      const formData = new FormData(schoolsControlRef.current);
      setSchoolsControl(formData.getAll("schools[]"));
    }

    if (periodsControlRef.current) {
      const formData = new FormData(periodsControlRef.current);

      setPeriodsControl(formData.getAll("periods[]"));
    }
  };

  useEffect(() => {
    const allSchools = [];
    const allPeriods = [];

    bibliography.map((bibEntry) => {
      allSchools.push(bibEntry.school);
      allPeriods.push(bibEntry.period);

      return bibEntry;
    });

    setSchools([...new Set(allSchools)]);

    setControls();
  }, []);

  useEffect(() => {
    if (selectedNode && selectedNode.attributes.type === "aya") {
      axios
        .get(`/api/verse/${selectedNode.id}`, {
          params: {
            page: pagination.page + 1,
            exclusive: constraintControl === "filter",
            schools: schoolsControl,
            periods: periodsControl,
            authors: authorsControl,
            link: links,
          },
        })
        .then((response) => {
          setRelevantPages(response.data.docs);
          setIsFetching(false);
          setPagination({
            hasNextPage: response.data.hasNextPage,
            hasPrevPage: response.data.hasPrevPage,
            limit: response.data.limit,
            nextPage: response.data.nextPage,
            page: response.data.page - 1,
            pagingCounter: response.data.pagingCounter,
            prevPage: response.data.prevPage,
            totalDocs: response.data.totalDocs,
            totalPages: response.data.totalPages,
          });
        });
    }
  }, [
    authorsControl,
    constraintControl,
    links,
    pagination.page,
    periodsControl,
    schoolsControl,
    selectedNode,
  ]);

  const disableInput = () => {
    if (constraintsControlRef.current) {
      const constraint = new FormData(constraintsControlRef.current).get(
        "constraints"
      );

      if (constraint === "core") {
        return true;
      }
    }

    return false;
  };

  const applyDisableFilter = (entry) => {
    const schoolsSelection = new FormData(schoolsControlRef.current).getAll(
      "schools[]"
    );

    const periodsSelection = new FormData(periodsControlRef.current).getAll(
      "periods[]"
    );

    let disabled = false;

    if (constraintsControlRef.current) {
      const constraint = new FormData(constraintsControlRef.current).get(
        "constraints"
      );

      if (constraint === "core") {
        disabled = true;
      }
    }

    if (!periodsSelection.includes(entry.period)) {
      disabled = true;
    }

    if (!schoolsSelection.includes(entry.school)) {
      disabled = true;
    }

    return disabled;
  };

  const renderAuthors = () => {
    return schools.map((school) => {
      return (
        <VisualisationControls key={`authors-${school}-index`}>
          <VisualisationControlsHeader>
            {normalizeUnicodeText(school)}
          </VisualisationControlsHeader>
          <VisualisationControlsUl>
            {bibliography
              .filter((entry) => entry.school === school)
              .map((entry, index) => {
                return (
                  <li key={`school-${school}-${index}`}>
                    <input
                      type="checkbox"
                      name="authors[]"
                      value={entry.author}
                      id={entry.author}
                      disabled={applyDisableFilter(entry)}
                      onChange={() => {
                        setControls();
                      }}
                    />
                    <VisualisationControlsLabel htmlFor={entry.author}>
                      {entry.author}
                    </VisualisationControlsLabel>
                  </li>
                );
              })}
          </VisualisationControlsUl>
        </VisualisationControls>
      );
    });
  };

  const setLinkedVerse = (label, links) => {
    setCoCitation(label);
    setLinks(links);
  };

  return (
    <>
      <ThreeColumns>
        <ToastContainer />
        <NetworkContainer>
          <FlexColumnDiv>
            <PageTitle>Visualization settings</PageTitle>
            <VisualisationControlsRow>
              <VisualisationControls>
                <form ref={visualizeControlRef}>
                  <VisualisationControlsHeader>
                    Visualize
                  </VisualisationControlsHeader>
                  <VisualisationControlsUl>
                    <li>
                      <input
                        type="radio"
                        name="visualize"
                        value="highlight"
                        id="highlight-visualize"
                        defaultChecked={true}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="highlight-visualize">
                        highlight selection
                      </VisualisationControlsLabel>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="visualize"
                        value="simplify"
                        id="simplify-visualize"
                        onChange={() => {
                          setControls();
                          constraintsControlRef.current.querySelector(
                            "#none-constraint"
                          ).checked = true;
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="simplify-visualize">
                        simplify to selection (filter)
                      </VisualisationControlsLabel>
                    </li>
                  </VisualisationControlsUl>
                </form>
              </VisualisationControls>
              <VisualisationControls>
                <form ref={constraintsControlRef}>
                  <VisualisationControlsHeader>
                    Constraints
                  </VisualisationControlsHeader>
                  <VisualisationControlsUl>
                    <li>
                      <input
                        type="radio"
                        name="constraints"
                        value="none"
                        id="none-constraint"
                        defaultChecked={true}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="none-constraint">
                        none
                      </VisualisationControlsLabel>
                    </li>
                    {visualizeControl !== "simplify" ? (
                      <li>
                        <input
                          type="radio"
                          name="constraints"
                          value="filter"
                          id="filter-constraint"
                          onChange={() => {
                            visualizeControlRef.current.reset();
                            setControls();
                          }}
                        />
                        <VisualisationControlsLabel htmlFor="filter-constraint">
                          exclusive to selected schools, period, and authors
                          (creativity)
                        </VisualisationControlsLabel>
                      </li>
                    ) : null}
                    {visualizeControl !== "simplify" ? (
                      <li>
                        <input
                          type="radio"
                          name="constraints"
                          value="selectedauthors"
                          id="filter-constraint"
                          onChange={() => {
                            visualizeControlRef.current.reset();
                            setControls();
                          }}
                        />
                        <VisualisationControlsLabel htmlFor="filter-constraint">
                          all selected authors cite verse
                        </VisualisationControlsLabel>
                      </li>
                    ) : null}
                    {visualizeControl !== "simplify" ? (
                      <li>
                        <input
                          type="radio"
                          name="constraints"
                          value="core"
                          id="filter-constraint"
                          onChange={() => {
                            setControls();
                          }}
                        />
                        <VisualisationControlsLabel htmlFor="filter-constraint">
                          cited by each school (core references)
                        </VisualisationControlsLabel>
                      </li>
                    ) : null}
                  </VisualisationControlsUl>
                </form>
              </VisualisationControls>
              <VisualisationControls>
                <form ref={schoolsControlRef}>
                  <VisualisationControlsHeader>
                    Schools
                  </VisualisationControlsHeader>
                  <VisualisationControlsUl>
                    {schoolNames.map((schoolName) => (
                      <li key={`${schoolName}-in-vis-control`}>
                        <input
                          type="checkbox"
                          name="schools[]"
                          value={schoolName}
                          id={schoolName}
                          disabled={disableInput()}
                          defaultChecked={true}
                          onChange={() => {
                            setControls();
                          }}
                        />
                        <VisualisationControlsLabel htmlFor={schoolName}>
                          {schoolName}
                        </VisualisationControlsLabel>
                      </li>
                    ))}
                  </VisualisationControlsUl>
                </form>
              </VisualisationControls>
              <VisualisationControls>
                <form ref={periodsControlRef}>
                  <VisualisationControlsHeader>
                    Periods
                  </VisualisationControlsHeader>
                  <VisualisationControlsUlAlternate>
                    <VisualisationControlsSeparator>
                      Formative
                    </VisualisationControlsSeparator>
                    <li>
                      <input
                        type="checkbox"
                        name="periods[]"
                        value="formative"
                        id="formative"
                        defaultChecked={true}
                        disabled={disableInput()}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="formative">
                        formative (8th c. - 9th c.)
                      </VisualisationControlsLabel>
                    </li>
                    <VisualisationControlsSeparator>
                      Classical
                    </VisualisationControlsSeparator>
                    <li>
                      <input
                        type="checkbox"
                        name="periods[]"
                        value="early classical"
                        id="earlyclassical"
                        defaultChecked={true}
                        disabled={disableInput()}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="earlyclassical">
                        early classical (10th c. - 11th c.)
                      </VisualisationControlsLabel>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="periods[]"
                        value="late classical"
                        id="lateclassical"
                        defaultChecked={true}
                        disabled={disableInput()}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="lateclassical">
                        late classical (12th c. - 13th c.)
                      </VisualisationControlsLabel>
                    </li>
                    <VisualisationControlsSeparator>
                      Postclassical
                    </VisualisationControlsSeparator>
                    <li>
                      <input
                        type="checkbox"
                        name="periods[]"
                        value="early postclassical"
                        id="earlypostclassical"
                        defaultChecked={true}
                        disabled={disableInput()}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="earlypostclassical">
                        early postclassical (14th c. - 16th c.)
                      </VisualisationControlsLabel>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="periods[]"
                        value="late postclassical"
                        id="latepostclassical"
                        defaultChecked={true}
                        disabled={disableInput()}
                        onChange={() => {
                          setControls();
                        }}
                      />
                      <VisualisationControlsLabel htmlFor="latepostclassical">
                        late postclassical (17th c. - 19th c.)
                      </VisualisationControlsLabel>
                    </li>
                  </VisualisationControlsUlAlternate>
                </form>
              </VisualisationControls>
            </VisualisationControlsRow>
            <Instruction>
              Filter earlier selection by author (nothing checked = every
              author)
            </Instruction>
            <VisualisationControlsRow>
              <AuthorsForm ref={authorsControlRef}>
                {renderAuthors()}
              </AuthorsForm>
            </VisualisationControlsRow>
            {allData.edges ? (
              <>
                <GraphHelp style={{ textAlign: "left", marginTop: "2rem" }}>
                  <p style={{ width: "100%" }}>
                    <b>Instructions: </b>
                  </p>
                  <span>
                    <InfoIcon color={theme.pentiaryColor} />
                    <i>hovering on a node &nbsp;</i> displays either a title or
                    verse number
                  </span>
                  <span>
                    <InfoIcon color={theme.pentiaryColor} />
                    <i>clicking on a verse &nbsp;</i> will load results from the
                    corpus in which the verse is cited
                  </span>
                  <span>
                    <InfoIcon color={theme.pentiaryColor} />
                    <i>only highlighted nodes &nbsp;</i> show corpus results
                    when clicked
                  </span>
                  <span>
                    <InfoIcon color={theme.pentiaryColor} />
                    <i>clicking on a title &nbsp;</i>
                    will show bibliographical information
                  </span>
                  <span>
                    <InfoIcon color={theme.pentiaryColor} />{" "}
                    <i>clicking and dragging &nbsp;</i>the mouse inside the box
                    will move the visualization
                  </span>
                </GraphHelp>
                <SigmaContainer
                  style={{
                    borderWidth: visualizeControl === "simplify" ? "0" : "1px",
                  }}
                >
                  <FlexRowDiv
                    onClick={() => {}}
                    style={{
                      background:
                        visualizeControl === "simplify"
                          ? theme.quartiaryColor
                          : "#fff",
                    }}
                  >
                    <Sigma
                      renderer="canvas"
                      style={{ width: "100%", height: "650px" }}
                      settings={{
                        clone: false,
                        batchEdgesDrawing: true,
                        defaultHoverLabelBGColor: "#002147",
                        defaultLabelBGColor: "#ddd",
                        activeFontStyle: "bold",
                        defaultLabelColor: "#000",
                        labelThreshold: 5.5,
                        defaultLabelHoverColor: "#fff",
                        fontStyle: "bold",
                        hoverFontStyle: "bold",
                        defaultLabelSize: 12,
                        minNodeSize: 1.5,
                        maxNodeSize: 5,
                        minEdgeSize: 0.1,
                        maxEdgeSize: 0.7,
                        autoRescale: true,
                        enableEdgeHovering: false,
                        hideEdgesOnMove: true,
                        mouseWheelEnabled: false,
                        animationsTime: 400,
                      }}
                      onClickNode={(e) => {
                        window.setTimeout(() => {
                          if (document.querySelector("#corpus-header")) {
                            const y =
                              document
                                .querySelector("#corpus-header")
                                .getBoundingClientRect().top +
                              window.pageYOffset;

                            window.scrollTo({ top: y, behavior: "smooth" });
                          }
                        }, 500);

                        const node = e.data.node;
                        setPagination({
                          page: 0,
                          totalPages: 0,
                          totalDocs: 0,
                        });

                        setSelectedNode(node);
                      }}
                    >
                      <SigmaLoader
                        graph={{
                          nodes: allData.nodes,
                          edges: allData.edges,
                        }}
                      />
                      <EdgeShapes default="curvedArrow" />
                      <SigmaControls />
                    </Sigma>
                    <Legend
                      style={{
                        backgroundColor:
                          visualizeControl === "simplify"
                            ? "rgba(255, 255, 255, 1)"
                            : "rgba(255, 255, 255, 0.5)",
                        borderWidth:
                          visualizeControl === "simplify" ? "0" : "1px",
                      }}
                    >
                      <FlexColumn>
                        <FlexRow>
                          {Object.entries(theme.components.legend.schools).map(
                            (value) => {
                              return (
                                <LegendEntry key={`legend-entry-${value}`}>
                                  <LegendColorBlock color={value[1]} />
                                  {value[0]}
                                </LegendEntry>
                              );
                            }
                          )}
                        </FlexRow>
                        <FlexRow
                          style={{
                            marginTop: "0.7rem",
                          }}
                        >
                          {visualizeControl === "highlight" ? (
                            <>
                              <LegendEntry>
                                <svg height="10" width="10">
                                  <circle
                                    cx="5"
                                    cy="5"
                                    r="5"
                                    fill={theme.components.legend.selected}
                                  />
                                </svg>
                                <span style={{ marginLeft: "1.1rem" }}>
                                  Selected
                                </span>
                              </LegendEntry>
                              <LegendEntry>
                                <svg height="10" width="10">
                                  <circle
                                    cx="5"
                                    cy="5"
                                    r="5"
                                    fill={theme.components.legend.unselected}
                                  />
                                </svg>
                                <span style={{ marginLeft: "1.1rem" }}>
                                  Unselected
                                </span>
                              </LegendEntry>
                            </>
                          ) : null}
                          {visualizeControl === "simplify" ? (
                            <>
                              <LegendEntry>
                                <svg height="10" width="10">
                                  <circle
                                    cx="5"
                                    cy="5"
                                    r="5"
                                    fill={theme.components.legend.intersected}
                                  />
                                </svg>
                                <span style={{ marginLeft: "1.1rem" }}>
                                  Shared citation
                                </span>
                              </LegendEntry>
                            </>
                          ) : null}
                        </FlexRow>
                      </FlexColumn>
                    </Legend>
                  </FlexRowDiv>
                </SigmaContainer>
                <CustomMetaRow>
                  <H2>Number of verses highlighted: {numVerses}</H2>
                  {constraintControl === "core" ? null : (
                    <H2>Number of authors: {numAuthors}</H2>
                  )}
                </CustomMetaRow>
              </>
            ) : null}
          </FlexColumnDiv>
        </NetworkContainer>
      </ThreeColumns>
      {isLoading ? <Loader /> : null}
      {selectedNode && selectedNode.attributes.type === "book" ? (
        <BookInfo
          author={selectedNode.attributes.author}
          title={selectedNode.attributes.title}
        />
      ) : null}
      {selectedNode && selectedNode.attributes.type === "aya" ? (
        <QuranDisplay
          surah={selectedNode.label.split("-")[0]}
          ayah={selectedNode.label.split("-")[1]}
        />
      ) : null}
      {selectedNode && selectedNode.attributes.type === "aya" ? (
        <VerseMetaInfo
          pages={relevantPages}
          pagination={pagination}
          surah={selectedNode.label.split("-")[0]}
          ayah={selectedNode.label.split("-")[1]}
        />
      ) : null}
      {selectedNode && selectedNode.attributes.type === "aya" ? (
        <Cluster
          key={`Cluster-for-${selectedNode.label.split("-")[0]}-${
            selectedNode.label.split("-")[1]
          }`}
          surahNum={selectedNode.label.split("-")[0]}
          ayahNum={selectedNode.label.split("-")[1]}
          setLinkedVerse={setLinkedVerse}
        />
      ) : null}
      {selectedNode && selectedNode.attributes.type === "aya" ? (
        <ThreeColumns>
          <BaseFlexColumnDiv style={{ paddingBottom: "1rem" }}>
            {links.length === 0 ? (
              <H1 id="corpus-header" style={{ margin: "3rem 0 0" }}>
                {"Corpus results"}
              </H1>
            ) : null}
            {links.length > 0 ? (
              <>
                <H1 id="corpus-header" style={{ margin: "3rem 0 0" }}>
                  {"Co-citation corpus results"}
                </H1>
                <SubInstruction
                  style={{ textAlign: "center", margin: "1rem 0 0" }}
                >
                  {`( ${selectedNode.label.split("-")[0]}:${
                    selectedNode.label.split("-")[1]
                  } linked to ${coCitation} )`}
                </SubInstruction>
                <LinkButton
                  onClick={() => {
                    setCoCitation("");
                    setLinks([]);
                    setControls();
                  }}
                >
                  {"< Return to regular results"}
                </LinkButton>
              </>
            ) : null}
            <SubInstruction
              style={{
                textAlign: "center",
                margin: "1.3rem 0",
                fontSize: "1.6rem",
              }}
            >
              Page {pagination.page + 1} of {pagination.totalPages}{" "}
            </SubInstruction>
            {!isFetching ? (
              relevantPages.map((p, index) => {
                return (
                  <Reader
                    key={`${p._id}-${index}-${new Date().getTime()}`}
                    sourceId={p.source}
                    startPage={p.pp}
                    tag={p.tag}
                    tagNr={p.tag_nr}
                    surah={selectedNode.label.split("-")[0]}
                    ayah={selectedNode.label.split("-")[1]}
                  />
                );
              })
            ) : (
              <Loader />
            )}
          </BaseFlexColumnDiv>
        </ThreeColumns>
      ) : null}
      {!isLoading && pagination.totalPages > 1 ? (
        <Sticky mode={"bottom"}>
          <PaginationContainer>
            <h2>Search result pages:</h2>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              pageCount={pagination.totalPages}
              forcePage={pagination.page}
              marginPagesDisplayed={0}
              pageRangeDisplayed={10}
              onPageChange={debounce((data) => {
                if (document.querySelector("#corpus-header")) {
                  const y =
                    document
                      .querySelector("#corpus-header")
                      .getBoundingClientRect().top + window.pageYOffset;

                  window.scrollTo({ top: y, behavior: "smooth" });
                }

                setIsFetching(true);
                setPagination(
                  Object.assign({}, pagination, { page: data.selected })
                );
              }, 500)}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </PaginationContainer>
        </Sticky>
      ) : null}
    </>
  );
};

export default SigmaNetwork;
