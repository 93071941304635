import React from "react";
import styled from "styled-components";

interface ThreeColumnWrapperProps {
  bgColor?: string;
  leftAlign?: boolean;
}

export const ThreeColumnWrapper = styled.div<ThreeColumnWrapperProps>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "transparent"};
`;

const Column = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 12.5%;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (max-width: 1199px) {
    flex-basis: 10%;
  }
`;

export const Main = styled(Column)<ThreeColumnWrapperProps>`
  flex-grow: 5;
  flex-basis: 33%;
  overflow-x: hidden;

  justify-content: ${(props) => (props.leftAlign ? "flex-start" : "center")};

  @media (max-width: 1023px) {
    display: flex;
    flex-basis: 100%;
    padding: 0 4rem;
  }

  @media (max-width: 1440px) {
    flex-basis: 80%;
  }
`;

const ThreeColumns = (props: {
  children: React.ReactNode;
  bgColor?: string;
  leftAlign?: boolean;
  style?: any;
}) => {
  return (
    <ThreeColumnWrapper bgColor={props.bgColor}>
      <Column />
      <Main leftAlign={props.leftAlign} style={props.style}>
        {props.children}
      </Main>
      <Column />
    </ThreeColumnWrapper>
  );
};

export default ThreeColumns;
