import React, { useContext, useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { GradientTealBlue } from "@visx/gradient";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisLeft, AxisBottom } from "@visx/axis";
import localPoint from "@visx/event/lib/localPoint";

import { defaultStyles, Tooltip, useTooltip } from "@visx/tooltip";
import styled, { ThemeContext } from "styled-components";

const verticalMargin = 120;
const horizontalMargin = 90;

interface ISurahData {
  _id: string;
  count: number;
}

// accessors
const getSurahName = (d: ISurahData) => d._id;
const getCitationCount = (d: ISurahData) => d.count;

export const tooltipStyles = {
  ...defaultStyles,
  minWidth: 180,
  borderRadius: 0,
};

export type BarsProps = {
  width: number;
  height: number;
  events?: boolean;
  data: any;
};

export const ToolTipLabel = styled.p`
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor}
  margin: 1rem 0;
  padding: 0.5rem;
  font-size: 1rem;
`;

const SurahReliance = ({ width, height, data }: BarsProps) => {
  // bounds
  const xMax = width - horizontalMargin - 70;
  const yMax = height - verticalMargin;
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const themeContext = useContext(ThemeContext);

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: data.map(getSurahName),
        padding: 0.4,
      }),
    [data, xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(getCitationCount))],
      }),
    [data, yMax]
  );

  const handleMouseOverBar = (event: any, datum: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event);

    showTooltip({
      tooltipLeft: coords ? coords?.x - 210 : 0,
      tooltipTop: coords ? coords?.y - 50 : 0,
      tooltipData: datum,
    });
  };

  return width < 10 ? null : (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <GradientTealBlue id="teal" />
        <rect width={width} height={height} fill={"#fff"} />
        <Group top={verticalMargin / 2}>
          {data.map((d: any) => {
            const letter = getSurahName(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getCitationCount(d)) ?? 0);
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            return (
              <Bar
                key={`bar-${letter}`}
                x={horizontalMargin + (barX ? barX : 0)}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={themeContext.secondaryColor}
                onMouseMove={(e) => handleMouseOverBar(e, d)}
                onMouseOut={() => hideTooltip()}
              />
            );
          })}
          <AxisLeft
            left={horizontalMargin}
            label={"Discovered in num tags"}
            scale={yScale}
            stroke={themeContext.primaryColor}
            tickStroke={themeContext.primaryColor}
            tickLabelProps={() => ({
              fill: themeContext.primaryColor,
              fontSize: 16,
              textAnchor: "end",
              dy: "0.33em",
              dx: "-0.33em",
            })}
            labelProps={{
              fill: themeContext.primaryColor,
              fontSize: 16,
              textAnchor: "middle",
              dx: "-2em",
            }}
          />
          <AxisBottom
            top={yMax}
            left={horizontalMargin}
            label={"Surah number"}
            scale={xScale}
            stroke={themeContext.primaryColor}
            numTicks={114}
            tickStroke={themeContext.primaryColor}
            tickLabelProps={() => ({
              fill: themeContext.primaryColor,
              fontSize: 16,
              textAnchor: "middle",
            })}
            labelProps={{
              fill: themeContext.primaryColor,
              fontSize: 16,
              textAnchor: "middle",
            }}
          />
        </Group>
      </svg>
      {tooltipOpen ? (
        <Tooltip left={tooltipLeft} top={tooltipTop} style={tooltipStyles}>
          <ToolTipLabel>Surah: {tooltipData?._id}</ToolTipLabel>
          <ToolTipLabel>Num citations: {tooltipData?.count}</ToolTipLabel>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default SurahReliance;
