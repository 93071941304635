import React from "react";
import styled from "styled-components";

import ZoomIn from "../Icons/ZoomIn";
import ZoomOut from "../Icons/ZoomOut";

const SigmaControlsContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.primaryColor};
    border-bottom: 5px solid ${(props) => props.theme.primaryColor};
    margin: 0.5rem;

    &:hover {
      transform: scale(0.95, 0.95);
    }
  }
`;

const SigmaControls = (props) => {
  return (
    <SigmaControlsContainer>
      <button
        onClick={() => {
          const c = props.sigma.camera;

          c.goTo({
            ratio: c.ratio * c.settings("zoomingRatio"),
          });
        }}
      >
        <ZoomOut />
      </button>
      <button
        onClick={() => {
          const c = props.sigma.camera;

          c.goTo({
            ratio: c.ratio / c.settings("zoomingRatio"),
          });
        }}
      >
        <ZoomIn />
      </button>
    </SigmaControlsContainer>
  );
};

export default SigmaControls;
