import { SchoolName } from "../Types";

interface INetworkNode {color: string; size: number; attributes: {type: string, group: SchoolName}; type?: string};

const colorAllNodes = (nodes: INetworkNode[], color: string) => {
  return nodes.map((node: INetworkNode) => {
    const newNode = Object.assign({}, node);
    newNode.color = color;

    return newNode;
  });
}

const setDefaultNodeConfiguration = (nodes: INetworkNode[], colors: {
  "Jaʿfarī": SchoolName,
  "Mālikī": SchoolName,
  "Shāfiʿī": SchoolName,
  "Ḥanafī": SchoolName,
  "Ḥanbalī": SchoolName
}) => {
  return nodes.map((node: INetworkNode) => {
    const newNode = Object.assign({}, node);

      if (node.attributes.type === "book") {
        newNode.size = 6;
        newNode.color = colors[node.attributes.group];
        newNode.type = "square";
      }
      if (node.attributes.type === "aya") {
        newNode.size = 1;
        newNode.color = "rgb(255,215,0)";
      }

      return newNode;
  });
}

export { colorAllNodes, setDefaultNodeConfiguration };
