import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

import SigmaNetwork from "./SigmaNetwork";
import Profile from "./Profile";
import ThreeColumns, { Main } from "./ThreeColumns";
import Stats from "./Stats";
import SearchVerse from "./SearchVerse";
import HistoricityPage from "./Historicity";
import FlexRow from "./FlexRow";
import Rankings from "./Rankings";

const ResultsContainer = styled.section``;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Brand = styled.span`
  font-family: "Lobster";
  color: ${(props) => props.theme.primaryColor};
  font-size: 2rem;
  margin-right: 1rem;
`;

const SubBrand = styled.span`
  font-family: "Open Sans";
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
  position: relative;
  top: 0.32rem;

  @media (max-width: 1299px) {
    display: none;
  }
`;

const PageContainer = styled.div`
  padding-bottom: 1rem;
  min-height: calc(100vh - 15.5rem);
`;

const LinksContainer = styled.ul`
  display: flex;
  align-items: center;
  position: relative;
  top: 0.3rem;
  margin-left: 3rem;
`;

const LinkContainer = styled.li`
  margin: 0 0.5rem;

  a {
    color: ${(props) => props.theme.components.link.default};

    &:active {
      color: ${(props) => props.theme.components.link.active};
    }

    &:hover {
      color: ${(props) => props.theme.components.link.hover};
    }
  }
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Copyright = styled.p`
  margin: 1rem 0;
  color: #fff;
  font-size: 0.9rem;
`;

const Footer = styled.footer`
  background-color: ${(props) => props.theme.primaryColor};
  padding: 0.5rem 0 1rem;

  h2 {
    font-weight: normal;
    border-left: 1px solid ${(props) => props.theme.secondaryColor};
    color: ${(props) => props.theme.secondaryColor};
    font-size: 1.3rem;
    margin: 1rem 0;
    padding: 0 0 0 1rem;
  }

  ul {
    margin: 0 0 0 5rem;
  }

  li {
    margin: 0.5rem 0;
    padding: 0 0 0 1rem;

    button {
      margin: 0 0.4rem 0 0;
    }

    &:first-of-type {
      padding: 0;
    }
  }
  a {
    color: #e2e2e2;
    line-height: 1.5;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.quartiaryColor};
      text-decoration: underline;
    }
  }

  ${Main} {
    justify-content: flex-end;
  }
`;

const Menu = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <Router>
      <ThreeColumns bgColor={themeContext.secondaryColor}>
        <NavContainer>
          <Nav>
            <BrandContainer>
              <Brand>Footprinter</Brand>
              <SubBrand>
                A Qur'an footprint in fiqh tracker and analysis aid
              </SubBrand>
            </BrandContainer>
            <LinksContainer>
              <LinkContainer>
                {/* Otherwise sigmajs breaks on quick change of page */}
                <a href="/search">Search</a>
              </LinkContainer>
              <span>/</span>
              <LinkContainer>
                {/* Otherwise sigmajs breaks on quick change of page */}
                <a href="/">Network</a>
              </LinkContainer>
              <span>/</span>
              <LinkContainer>
                <Link to="/historicity">Historicity</Link>
              </LinkContainer>
              <span>/</span>

              <LinkContainer>
                <Link to="/stats">Statistics</Link>
              </LinkContainer>
              <span>/</span>
              <LinkContainer>
                <Link to="/rankings">Rankings</Link>
              </LinkContainer>
              <span>/</span>
              <LinkContainer>
                <Link to="/about">About</Link>
              </LinkContainer>
            </LinksContainer>
          </Nav>
        </NavContainer>
      </ThreeColumns>
      <Switch>
        <PageContainer>
          <Route exact={true} path="/search">
            <SearchVerse />
          </Route>
          <Route exact={true} path="/search/:surah_num/:ayah_num">
            <SearchVerse />
          </Route>
          <Route exact={true} path="/">
            <ResultsContainer>
              <SigmaNetwork />
            </ResultsContainer>
          </Route>
          <Route exact={true} path="/about">
            <Profile />
          </Route>
          <Route exact={true} path="/stats">
            <Stats />
          </Route>
          <Route exact={true} path="/rankings">
            <Rankings />
          </Route>
          <Route exact={true} path="/historicity">
            <HistoricityPage />
          </Route>
        </PageContainer>
      </Switch>
      <Footer id="footer">
        <ThreeColumns leftAlign={true}>
          <FlexRow>
            <ul>
              <li>
                <h2>Share</h2>
              </li>
              <li>
                <FacebookShareButton
                  quote={
                    "Footprinter: A Qur'an footprint tracker and analysis aid"
                  }
                  url={"https://quran-in-fiqh.hum.uu.nl/"}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={"https://quran-in-fiqh.hum.uu.nl/"}
                  hashtags={["quranfootprinter"]}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={"https://quran-in-fiqh.hum.uu.nl/"}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </li>
            </ul>
            <ul>
              <li>
                <h2>Links</h2>
              </li>
              <li>
                <a href="/about">About us</a>
              </li>
              <li>
                <a href="https://sensis.sites.uu.nl/">Sensis project</a>
              </li>
              <li>
                <Copyright>© Dr. Yusuf Celik</Copyright>
              </li>
            </ul>
          </FlexRow>
        </ThreeColumns>
      </Footer>
    </Router>
  );
};

export default Menu;
