import styled from "styled-components";

const H1 = styled.h1`
  font-family: "Lobster";
  font-size: 2rem;
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  width: 100%;
`;

const H2 = styled.h2`
  font-family: "Lobster";
  font-size: 1.7rem;
  color: ${(props) => props.theme.primaryColor}; ;
`;

export { H1, H2 };
