import React from "react";

const ZoomIn = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <title>zoom-in</title>
      <path d="M26 22h-4v-4c0-0.552-0.448-1-1-1s-1 0.448-1 1v4h-4c-0.552 0-1 0.448-1 1s0.448 1 1 1h4v4c0 0.552 0.448 1 1 1s1-0.448 1-1v-4h4c0.552 0 1-0.448 1-1s-0.448-1-1-1z"></path>
      <path d="M36.864 29.94l-2.57-2.574c0.454-1.376 0.708-2.84 0.708-4.366 0-7.718-6.282-14-14-14s-14 6.282-14 14 6.282 14 14 14c1.526 0 2.992-0.254 4.368-0.708l4.646 4.64 0.128 0.11c1.202 1.012 2.696 1.568 4.208 1.568 3.452 0 6.26-2.808 6.26-6.262 0-1.68-0.656-3.256-1.848-4.436l-1.9-1.972zM11 23c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10z"></path>
    </svg>
  );
};

export default ZoomIn;
