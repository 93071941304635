import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ThreeColumns from "./ThreeColumns";
import { H1, H2 } from "./Typography";
import { toast, ToastContainer } from "react-toastify";

export const MetaRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  H2 {
    margin: 0.5rem 1rem 0.5rem 0;
    border-right: 1px solid ${(props) => props.theme.primaryColor};
    padding-right: 1rem;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  max-height: 9rem;
  overflow-y: auto;

  li {
    font-family: "Arial";
  }
`;

const VerseMetaInfo = (props: {
  pages: [];
  pagination: { totalDocs: number; page: number; totalPages: number };
  surah: string;
  ayah: string;
}) => {
  const [statistics, setStatistics] = useState([
    {
      authors: [],
      titles: [],
      periods: [],
      schools: [],
      count: 0,
    },
  ]);

  useEffect(() => {
    axios
      .get(`/api/statistics/${props.surah}/${props.ayah}`)
      .then((res) => {
        setStatistics(res.data);
      })
      .catch((_) =>
        toast.error("Could not get verse specific statistics. Try again later.")
      );
  }, [props.surah, props.ayah]);

  return (
    <ThreeColumns>
      <ToastContainer />
      {props.pages.length > 0 && statistics && statistics.length > 0 ? (
        <FlexColumn>
          <H1 style={{ marginTop: "2rem" }}>
            Overall statistics of {`${props.surah}:${props.ayah}`}
          </H1>
          <MetaRow>
            <FlexColumn>
              <H2>Discovered at {statistics[0].count} locations</H2>
            </FlexColumn>
            <FlexColumn>
              <H2>Num schools: {statistics[0].schools.length}</H2>
              <List>
                {statistics[0].schools.map((school) => {
                  return <li key={`meta-info-school-${school}`}>{school}</li>;
                })}
              </List>
            </FlexColumn>

            <FlexColumn>
              <H2 style={{ borderRight: 0, paddingRight: 0 }}>
                Num authors: {statistics[0].authors.length}
              </H2>
              <List>
                {statistics[0].authors.map((author) => {
                  return <li key={`meta-info-author-${author}`}>{author}</li>;
                })}
              </List>
            </FlexColumn>
          </MetaRow>
        </FlexColumn>
      ) : null}
    </ThreeColumns>
  );
};

export default VerseMetaInfo;
