import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import Loader, { LoaderText } from "./Loader";
import { VisualizationToolTip } from "./Cluster";
import Portal from "./Portal";
import { LinkButton } from "./Button";

const Header = styled.header`
  display: flex;
  align-items: baseline;
`;

const Sign = styled.span`
  font-family: "lobster";
  font-size: 2rem;
  color: ${(props) => props.theme.primaryColor};
  margin-right: 1rem;
`;

const Label = styled.span`
  font-family: "Arial";
  font-size: 1.1rem;
  color: ${(props) => props.theme.primaryColor};
  margin-right: 0.5rem;
  font-style: italic;

  &:nth-child(3n) {
    margin-left: 1rem;
  }
`;

const Author = styled.span`
  font-family: "Arial";
  font-size: 1.1rem;
  color: ${(props) => props.theme.primaryColor};
  margin-right: 1rem;
`;

const Title = styled.span`
  font-family: "Arial";
  font-size: 1.1rem;
  color: ${(props) => props.theme.primaryColor};
  margin-right: 1rem;
  line-height: 1.5;
`;

const Tag = styled.span`
  font-family: "Amiri";
  direction: rtl;
  color: ${(props) => props.theme.primaryColor};
  padding: 1rem 1rem 0.5rem;
  font-size: 1.4rem;
  line-height: 2;
  background-color: ${(props) => props.theme.quartiaryColor};
`;

const Nr = styled(Tag)`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.primaryColor};
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  font-size: 1rem;

  h2 {
    margin: 1rem 0 0.7rem;
  }

  li {
    margin: 0.2rem;

    a {
      outline: none;
      cursor: pointer;

      color: ${(props) => props.theme.primaryColor};

      &:active: {
        color: ${(props) => props.theme.primaryColor};
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .pagination {
    flex-direction: row;
    display: flex;
  }

  .active {
    border: 1px solid ${(props) => props.theme.primaryColor};
    padding: 0.2rem;
    border-radius: 3px;
    user-select: none;
  }
`;

interface IPageBodyProps {
  tagNr: number;
}

const PageBody = styled.div<IPageBodyProps>`
  font-family: "Amiri";
  direction: rtl;
  line-height: 2.2;
  border: 1px solid ${(props) => props.theme.primaryColor};
  padding: 1rem;
  margin: 0 auto;
  font-size: 1.325rem;
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: auto;

  * {
    font-family: "Amiri";
  }

  ${LoaderText} {
    font-family: "Lobster";
  }

  mark {
    background-color: ${(props) => props.theme.components.reader.color};
    padding: 0.2rem;
    color: #000;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: ${(props) => props.theme.quartiaryColor};
    }
  }

  .rfdBold2 {
    color: green;
  }
`;

const ReaderFlexContainer = styled.div`
  position: relative;
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80rem;
`;

const ReaderContainer = styled.div`
  width: 100%;

  h3 {
    font-family: "Arial";
    text-align: right;
    margin: 1rem 0;
  }
`;

const AssociationContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`;

const CandidateLabel = styled.span`
  margin: 0 0.5rem;
`;

const ToolTipEntry = styled.li`
  color: ${(props) => props.theme.pentiaryColor};
  margin: 1rem 0;
  font-family: "Open Sans";
  font-size: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
`;

const ToolTipEntryAlternative = styled(ToolTipEntry)`
  color: ${(props) => props.theme.primaryColor};
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const QuranCitation = styled.p`
  font-family: "Amiri";
  line-height: 2;
  font-size: 1.2rem;
  direction: rtl;
  text-align: right;

  mark {
    font-family: "Amiri";
    background: none;
    color: #000;
    user-select: none;
  }
`;

const QuranVerseNumber = styled.span`
  font-weight: 700;
  font-size: 1rem;
  margin-left: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.primaryColor};
`;

const TooltipLabel = styled.span`
  font-family: "Lobster";
  font-size: 1.3rem;
  color: ${(props) => props.theme.primaryColor};
`;

const ReaderTooltip = styled(VisualizationToolTip)`
  position: absolute;
  padding: 1rem;
  max-width: 28rem;
  min-width: 28rem;
`;

const TooltipVersesContainer = styled.section`
  max-height: 22rem;
  overflow-y: auto;
`;

interface ICandidate {
  surah_num: string;
  ayah_num: string;
  ayah_body: string;
}

const normalize_text = (text: string) => {
  //remove special characters
  text = text.replace(/([^\u0621-\u063A\u0641-\u064A\u0660-\u0669a-z\s])/g, "");

  //normalize Arabic
  text = text.replace(/-/g, "");
  text = text.replace(/\s+$/g, "");
  text = text.replace(/^\s+/g, "");
  text = text.replace(/\s+/g, " ");
  text = text.replace(
    /(([^\u0620-\u0669])و\s(?=[\u0620-\u0669]+)|^و\s(?=[\u0620-\u0669]+))/gm,
    " و"
  );
  text = text.replace(/\{/g, "");
  text = text.replace(/\}/g, "");

  return text.trim();
};

interface IMatch {
  citation: string;
  candidates: ICandidate[];
}

const Reader = (props: {
  sourceId: string;
  startPage: number;
  tag: string;
  tagNr: number;
  surah: string;
  ayah: string;
}) => {
  const [page, setPage] = useState({ main: "" } as any);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingVerseData, setIsLoadingVerseData] = useState(false);

  const [candidates, setCandidates] = useState([] as ICandidate[]);
  const [hoverCoordinates, setHoverCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [hoverRequest, setHoverRequest] = useState("");
  const [hoveredVerseData, setHoveredVerseData] = useState([] as IMatch[]);

  const [pagination, setPagination] = useState({
    page: props.startPage,
    totalPages: 50000,
  });

  const readerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverRequest.length > 0) {
      const source = axios.CancelToken.source();
      setIsLoadingVerseData(true);

      axios
        .get(`/api/match/?tag=${normalize_text(hoverRequest)}`, {
          cancelToken: source.token,
        })
        .then((response: any) => {
          setIsLoadingVerseData(false);
          setHoveredVerseData(response.data);
        })
        .catch((err) => {
          if (err.constructor.name === "Cancel") {
            setIsLoadingVerseData(false);

            return;
          }

          console.log(err);
        });

      return () => {
        source.cancel();
      };
    }
  }, [hoverRequest]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`/api/match/?tag=${normalize_text(props.tag)}`, {
        cancelToken: source.token,
      })
      .then((response: any) => {
        const allCandidates: any[] = [];

        response.data.map((entry: any) => {
          allCandidates.push(...entry.candidates);

          return entry;
        });

        setCandidates(
          allCandidates.filter(
            (entry: ICandidate) =>
              entry.surah_num !== props.surah || entry.ayah_num !== props.ayah
          )
        );
      })
      .catch((err) => {
        if (err.constructor.name === "Cancel") {
          return;
        }

        console.log(err);
      });

    return () => {
      source.cancel();
    };
  }, [props.ayah, props.surah, props.tag]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`/api/page/${props.sourceId}/${pagination.page}`, {
        cancelToken: source.token,
      })
      .then((response: any) => {
        setPage(response.data.doc);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.constructor.name === "Cancel") {
          return;
        }

        console.log(err);
      });

    return () => {
      source.cancel();
    };
  }, [props.sourceId, props.startPage, pagination.page]);

  const preProcessPage = (page: string) => {
    let count = 0;
    let processedPage = page
      .replace(/<a.*?>(.+?)<\/a>/gm, "$1")
      .replace(/\[\s.+?\s\]/gm, "")
      .replace("ـــــــــــــــــــــــــــــQ", "<h3>Ḥāshiya: </h3>")
      .replace("ـــــــــــــــــــــــــــــ", "<h3>Ḥāshiya: </h3>");

    if (props.startPage === pagination.page) {
      processedPage = processedPage.replace(/<mark>/g, (match) => {
        count = count + 1;

        if (props.tagNr + 1 === count) {
          return "<mark class='selected'>";
        }

        return match;
      });
    }

    return processedPage;
  };

  const renderQuranCitation = (quranText: string) => {
    quranText = normalize_text(quranText)
      .trim()
      .replace(
        new RegExp(`(${normalize_text(hoverRequest.trim())})`, "gm"),
        "<mark>$1</mark>"
      );

    return (
      <QuranCitation
        dangerouslySetInnerHTML={{
          __html: quranText,
        }}
      />
    );
  };

  const renderPortalContent = () => {
    if (isLoadingVerseData) {
      return (
        <ReaderTooltip
          style={{
            top: `${hoverCoordinates.y}px`,
            left: `${hoverCoordinates.x}px`,
          }}
        >
          <Loader text="Loading match" />
        </ReaderTooltip>
      );
    }

    if (
      hoverRequest.length > 0 &&
      readerRef.current &&
      hoveredVerseData.length > 0
    ) {
      return (
        <ReaderTooltip
          style={{
            top: `${hoverCoordinates.y}px`,
            left: `${hoverCoordinates.x}px`,
          }}
        >
          <ul>
            <ToolTipEntry
              style={{
                justifyContent: "flex-end",
                margin: "0 0 0.5rem",
              }}
            >
              <LinkButton
                style={{ margin: 0 }}
                onClick={() => setHoverRequest("")}
              >
                Close panel
              </LinkButton>
            </ToolTipEntry>
          </ul>
          {hoveredVerseData.map((entry) => {
            return (
              <TooltipVersesContainer>
                {entry.candidates.map((candidate) => {
                  return (
                    <ul>
                      <ToolTipEntryAlternative>
                        <span>
                          {entry.candidates.length === 1
                            ? "This passage refers to verse:"
                            : "This passage could refer to:"}
                        </span>
                        <QuranVerseNumber>{`${candidate.surah_num}:${candidate.ayah_num}`}</QuranVerseNumber>
                      </ToolTipEntryAlternative>
                      <ToolTipEntry>
                        <TooltipLabel>Full-text</TooltipLabel>
                      </ToolTipEntry>
                      <ToolTipEntry style={{ justifyContent: "flex-end" }}>
                        {renderQuranCitation(candidate.ayah_body)}
                      </ToolTipEntry>
                    </ul>
                  );
                })}
              </TooltipVersesContainer>
            );
          })}
        </ReaderTooltip>
      );
    }
  };

  return (
    <ReaderFlexContainer ref={readerRef}>
      <ReaderContainer>
        <Header>
          <Sign>Reader</Sign>
          <Label>author: </Label>
          <Author>
            {page.author} (d. {page.hijri}/{page.gregorian})
          </Author>
          <Label>title: </Label>
          <Title>{page.title}</Title>
          <Label>school: </Label>
          <Title>{page.school}</Title>
        </Header>
        <AssociationContainer>
          <Label>Association based on: </Label>
          <Tag>
            {props.tag
              .replace(/[{}]/gm, "")
              .replace(/[^\u0600-\u06FF\s]/gm, "")}
          </Tag>
          <Label>Tag nr: </Label>
          <Nr>{props.tagNr + 1}</Nr>
        </AssociationContainer>
        {candidates.length > 0 ? (
          <AssociationContainer>
            <Label>
              Citation could also refer to:
              {candidates
                .slice(0, 6)
                .map((candidate: ICandidate, index: number) => (
                  <CandidateLabel key={`${page._id}-candidate-ref-${index}`}>
                    {candidate.surah_num}:{candidate.ayah_num}
                  </CandidateLabel>
                ))}
              {candidates.length > 6 ? `and ${candidates.length - 6} more` : ""}
            </Label>
          </AssociationContainer>
        ) : null}
        <PageBody tagNr={props.tagNr}>
          {isLoading ? <Loader text="Loading corpus page" /> : null}
          <>
            <div
              onClick={() => setHoverRequest("")}
              onMouseOver={(e) => {
                if (readerRef.current) {
                  const target = e.target as HTMLElement;
                  var bounds = readerRef.current.getBoundingClientRect();

                  if (target.nodeName === "MARK") {
                    setHoverRequest(target.innerText);
                    setHoverCoordinates({
                      x: e.pageX - (e.pageX > bounds.width / 2 ? 448 : 0),
                      y: e.pageY + 16,
                    });
                  }
                }
              }}
              dangerouslySetInnerHTML={{ __html: preProcessPage(page.main) }}
            ></div>
            {page.hasOwnProperty("hashiyah") ? (
              <>
                <h3>Ḥāshiya: </h3>
                <div
                  onClick={() => setHoverRequest("")}
                  onMouseOver={(e) => {
                    if (readerRef.current) {
                      const target = e.target as HTMLElement;
                      var bounds = readerRef.current.getBoundingClientRect();

                      if (target.nodeName === "MARK") {
                        setHoverRequest(target.innerText);
                        setHoverCoordinates({
                          x: e.pageX - (e.pageX > bounds.width / 2 ? 448 : 0),
                          y: e.pageY + 16,
                        });
                      }
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: preProcessPage(page.hashiyah),
                  }}
                ></div>
              </>
            ) : null}
          </>
        </PageBody>
        <Portal id="verse-info">{renderPortalContent()}</Portal>
        <PaginationContainer>
          <h2>corpus page:</h2>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={pagination.totalPages}
            forcePage={pagination.page}
            marginPagesDisplayed={0}
            pageRangeDisplayed={10}
            onPageChange={(data) => {
              console.log(data);
              setPagination(
                Object.assign({}, pagination, { page: data.selected })
              );
            }}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </PaginationContainer>
      </ReaderContainer>
    </ReaderFlexContainer>
  );
};

export default Reader;
