import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IslamicArtContainer } from "./QuranDisplay";

interface IBookInfoProps {
  author: string;
  title: string;
}

interface Title {
  _id: string;
  school: string;
  title: string;
  author: string;
  hijri: number;
  gregorian: number;
  tokens: number;
  period: string;
  ddate: number;
}

interface TitlesResponse {
  docs: Title[];
}

const BookInfoStyle = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
  }

  span {
    border: 1px solid ${(props) => props.theme.tertiaryColor};
    font-family: "Arial";
    background: #fff;
    margin: 1rem;
    padding: 0.25rem 1rem;
  }
`;

const BookInfo: React.FC<IBookInfoProps> = (props) => {
  const [title, setTitle] = useState({} as Title);

  useEffect(() => {
    axios
      .get<TitlesResponse>(
        `api/titles?title=${props.title}&author=${props.author}`
      )
      .then((results) => setTitle(results.data.docs[0]));
  }, [props.author, props.title]);

  return (
    <IslamicArtContainer>
      <BookInfoStyle>
        <li>
          <span>School: </span>
          <span>{title.school}</span>
        </li>
        <li>
          <span>Author: </span>
          <span>{title.author}</span>
        </li>
        <li>
          <span>Title: </span>
          <span>{title.title}</span>
        </li>
        <li>
          <span>Hijri: </span>
          <span>{title.hijri}</span>
        </li>
        <li>
          <span>Gregorian: </span>
          <span>{title.gregorian}</span>
        </li>
      </BookInfoStyle>
    </IslamicArtContainer>
  );
};

export default BookInfo;
