import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTable, useExpanded, usePagination } from "react-table";
import { H1 } from "./Typography";
import Axios from "axios";
import OriginTree from "./OriginTree";
import useFetchCorpusResults from "./useFetchCorpusResults";
import ThreeColumns from "./ThreeColumns";
import { SecondaryButton } from "./Button";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { toast, ToastContainer } from "react-toastify";

const SelectButton = styled.label`
  font-family: "Arial";
  color: ${(props) => props.theme.primaryColor};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-right-width: 0;
  padding: 0.375rem 0.75rem;

  &:last-of-type {
    border-right-width: 1px;
  }

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

const SelectButtons = styled.form`
  margin: 1rem auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  border: 1.6rem solid ${(props) => props.theme.sistiaryColor};
  border-image: ${(props) =>
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23${props.theme.sistiaryColor.replace(
        "#",
        ""
      )}' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23${props.theme.sistiaryColor.replace(
        "#",
        ""
      )}'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")`}
    25;
  max-width: 30rem;

  input:checked + ${SelectButton} {
    background-color: ${(props) => props.theme.components.selectButtons.color};

    &:hover {
      color: #5a6078;
      cursor: default;
    }
  }
`;

const PageStyling = styled.div`
  display: flex;
  flex-direction: column;

  ${H1} {
    margin: 2rem 0 1rem;
  }
`;

const Styles = styled.div`
  display: block;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 95%;
    padding: 1rem;
  }

  @media (min-width: 1024px) {
    width: 76%;
  }

  @media (min-width: 1441px) {
    width: 63%;
  }

  .tableWrap {
    display: block;
    margin-bottom: 2rem;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1.6rem solid ${(props) => props.theme.sistiaryColor};
    border-image: ${(props) =>
        `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23${props.theme.sistiaryColor.replace(
          "#",
          ""
        )}' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23${props.theme.sistiaryColor.replace(
          "#",
          ""
        )}'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")`}
      25;
  }

  .pagination {
    margin-bottom: 1rem;

    button {
      font-family: "Lobster";
      color: ${(props) => props.theme.primaryColor};
      font-size: 1.4rem;
      border: 0;
      padding: 0.25rem 1rem;
      background: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    margin-top: 1rem;

    svg {
      margin: 0 auto;
      display: flex;
    }

    thead {
      tr {
        :first-child {
          th {
            font-size: 1.5rem;
            font-family: "Lobster";
            color: ${(props) => props.theme.primaryColor};
          }
        }
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      font-family: "Arial";
      margin: 0;
      padding: 0.5rem;
      border: 1px solid ${(props) => props.theme.primaryColor};
      line-height: 2;

      width: 1%;

      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const getSchool = (scholar) => {
  if (scholar === "al-Shāfiʿī") {
    return "Shāfiʿī";
  }

  if (scholar === "Mālik b. Anas") {
    return "Mālikī";
  }

  if (scholar === "al-Shaybānī") {
    return "Ḥanafī";
  }
};

const getTitle = (scholar) => {
  if (scholar === "shafi") {
    return "al-Umm";
  }

  if (scholar === "malik") {
    return "al-Muwaṭṭaʾ";
  }

  if (scholar === "shaybani") {
    return "al-Aṣl";
  }
};

const getAuthor = (scholar) => {
  if (scholar === "shafi") {
    return "al-Shāfiʿī";
  }

  if (scholar === "malik") {
    return "Mālik b. Anas";
  }

  if (scholar === "shaybani") {
    return "al-Shaybānī";
  }
};

function Table(props) {
  const { data, columns, renderRowSubComponent, dataIndex } = props;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      autoResetPage: false,
    },
    useExpanded,
    usePagination
  );

  useEffect(() => {
    gotoPage(0);
  }, [dataIndex, gotoPage]);

  // Render the UI for your table
  return (
    <div className="tableWrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </div>
  );
}

const HistoricityPage = () => {
  const [data, setData] = useState({
    shafi: [],
    shaybani: [],
    malik: [],
  });

  const [
    quranDisplay,
    ,
    relevantPages,
    pagination,
    verse,
    setVerse,
    ,
    setFilters,
  ] = useFetchCorpusResults();

  useEffect(() => {
    Axios.get("/graphs/genealogy.json")
      .then((res) => {
        setData(res.data);
      })
      .catch((_) => {
        toast.error(
          "Something went wrong with getting the genealogy list. Try again later."
        );
      });
  }, []);

  useEffect(() => {
    if (document.querySelector("#quran-verse")) {
      const y =
        document.querySelector("#quran-verse").getBoundingClientRect().top +
        window.pageYOffset -
        256;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [verse, pagination]);

  const [cluster, setCluster] = useState();

  const [dataSelection, setDataSelection] = useState("shafi");

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <SecondaryButton
            onClick={() => {
              setCluster(row.original);

              setFilters({
                schools: [getSchool(row.original.name)],
              });

              setVerse({
                surah_num: row.values.surah_num,
                ayah_num: row.values.ayah_num,
              });
            }}
          >
            display
          </SecondaryButton>
        ),
      },
      {
        Header: "Verse",
        columns: [
          {
            Header: "Surah",
            accessor: "surah_num",
          },
          {
            Header: "Ayah",
            accessor: "ayah_num",
          },
        ],
      },
      {
        Header: "Origin",
        columns: [
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Title",
            accessor: "title",
          },
        ],
      },
      {
        Header: "Authors",
        columns: [
          {
            Header: "Formative",
            accessor: (data) => {
              return data.formative.join(", ");
            },
          },
          {
            Header: "Early classical",
            accessor: (data) => {
              return data.early_classical.join(", ");
            },
          },
          {
            Header: "Late classical",
            accessor: (data) => {
              return data.late_classical.join(", ");
            },
          },
          {
            Header: "Early postclassical",
            accessor: (data) => {
              return data.early_postclassical.join(", ");
            },
          },
          {
            Header: "Late postclassical",
            accessor: (data) => {
              return data.late_postclassical.join(", ");
            },
          },
        ],
      },
    ],
    [setFilters, setVerse]
  );

  const setSelection = () => {
    console.log("setting selection");
  };

  return (
    <PageStyling>
      <ToastContainer />
      <H1>Historicity</H1>
      <SelectButtons>
        <input
          type="radio"
          id="shafii"
          name="scholar"
          value="shafi"
          hidden={true}
          defaultChecked={dataSelection === "shafi"}
          onChange={(e) => {
            setDataSelection(e.currentTarget.value);
            setVerse({
              surah_num: "",
              ayah_num: "",
            });
          }}
        />
        <SelectButton htmlFor="shafii">al-Shāfiʿī</SelectButton>
        <input
          type="radio"
          id="malik"
          name="scholar"
          value="malik"
          hidden={true}
          defaultChecked={dataSelection === "malik"}
          onChange={(e) => {
            setDataSelection(e.currentTarget.value);
            setVerse({
              surah_num: "",
              ayah_num: "",
            });
          }}
        />
        <SelectButton htmlFor="malik">Mālik b. Anas</SelectButton>
        <input
          type="radio"
          id="shaybani"
          name="scholar"
          value="shaybani"
          hidden={true}
          defaultChecked={dataSelection === "shaybani"}
          onChange={(e) => {
            setDataSelection(e.currentTarget.value);
            setVerse({
              surah_num: "",
              ayah_num: "",
            });
          }}
        />
        <SelectButton htmlFor="shaybani">al-Shaybānī</SelectButton>
      </SelectButtons>
      <Styles>
        {data ? (
          <Table
            dataIndex={getAuthor(dataSelection)}
            columns={columns}
            setSelection={setSelection}
            data={data[dataSelection].map((entry) => ({
              ...entry,
              title: getTitle(dataSelection),
              name: getAuthor(dataSelection),
            }))}
          />
        ) : null}
      </Styles>
      {quranDisplay}
      {cluster ? (
        <H1 style={{ margin: "3rem 0 2rem" }}>Historicity tree</H1>
      ) : null}
      {cluster ? (
        <ThreeColumns style={{ maxWidth: "80rem" }}>
          <ParentSize>
            {({ width }) =>
              verse.ayah_num !== "" ? (
                <OriginTree width={width} height={400} cluster={cluster} />
              ) : null
            }
          </ParentSize>
        </ThreeColumns>
      ) : null}
      {relevantPages() ? (
        <H1 style={{ margin: "3rem 0 0" }}>Corpus results</H1>
      ) : null}
      <ThreeColumns>{relevantPages()}</ThreeColumns>
      {pagination}
    </PageStyling>
  );
};

export default HistoricityPage;
