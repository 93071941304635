import React from "react";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Point } from "@visx/point";
import { Line, LineRadial } from "@visx/shape";
import styled from "styled-components";
import { FlexColumn } from "./Flex";

const orange = "#ff9933";
export const pumpkin = "#f5810c";
const silver = "#d9d9d9";
export const background = "#FAF7E9";

export const RadarTitle = styled.h2`
  margin: 1rem 0;
  font: "Lobster";
  font-size: 1.1rem;
  font-style: italic;
  color: ${(props) => props.theme.primaryColor};
`;

const degrees = 360;

const y = (d: { distance: number }) => d.distance;

const genAngles = (length: number) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length),
  }));

const genPoints = (length: number, radius: number) => {
  const step = (Math.PI * 2) / length;
  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step),
    y: radius * Math.cos(i * step),
  }));
};

function genPolygonPoints<Datum>(
  dataArray: Datum[],
  scale: (_: number) => number,
  getValue: (_: Datum) => number
) {
  const step = (Math.PI * 2) / dataArray.length;
  const points: { x: number; y: number }[] = new Array(dataArray.length).fill({
    x: 0,
    y: 0,
  });
  const pointString: string = new Array(dataArray.length + 1)
    .fill("")
    .reduce((res, _, i) => {
      if (i > dataArray.length) return res;
      const xVal = scale(getValue(dataArray[i - 1])) * Math.sin(i * step);
      const yVal = scale(getValue(dataArray[i - 1])) * Math.cos(i * step);
      points[i - 1] = { x: xVal, y: yVal };
      res += `${xVal},${yVal} `;
      return res;
    });

  return { points, pointString };
}

const defaultMargin = { top: 0, left: 80, right: 80, bottom: 80 };

export type RadarProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  levels?: number;
  data: Array<{ school: string; distance: number }>;
  title: string;
  style?: any;
};

export default function RadarChart({
  width,
  height,
  levels = 2,
  margin = defaultMargin,
  data,
  style,
}: RadarProps) {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 2;

  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [degrees, 0],
  });

  const yScale = scaleLinear<number>({
    range: [0, radius],
    domain: [0, Math.max(...data.map(y)) * 1.2],
  });

  const webs = genAngles(data.length);
  const points = genPoints(data.length, radius);
  const polygonPoints = genPolygonPoints(data, (d) => yScale(d) ?? 0, y);
  const zeroPoint = new Point({ x: 0, y: 0 });

  const getLabelPosY = (point: number, points: any) => {
    if (
      Math.sign(point) === 1 &&
      Math.max(...points.map((p: any) => p.y)) === point
    ) {
      return "1em";
    }

    if (
      Math.sign(point) === -1 &&
      Math.min(...points.map((p: any) => p.y)) === point
    ) {
      return "-0.33em";
    }

    return "0.33em";
  };

  const getLabelPosX = (point: number, points: any) => {
    if (
      Math.sign(point) === 1 &&
      Math.max(...points.map((p: any) => p.y)) === point
    ) {
      return "0.33em";
    }

    if (
      Math.sign(point) === -1 &&
      Math.min(...points.map((p: any) => p.y)) === point
    ) {
      return "-3em";
    }

    return "-1.3em";
  };

  return width < 10 ? null : (
    <FlexColumn style={style}>
      <svg width={width} height={height}>
        <rect fill={background} width={width} height={height} />
        <Group top={height / 2 - margin.top} left={width / 2}>
          {[...new Array(levels)].map((_, i) => (
            <LineRadial
              key={`web-${i}`}
              data={webs}
              angle={(d) => radialScale(d.angle) ?? 0}
              radius={((i + 1) * radius) / levels}
              fill="none"
              stroke={silver}
              strokeWidth={2}
              strokeOpacity={0.8}
              strokeLinecap="round"
            />
          ))}
          {[...new Array(data.length)].map((_, i) => (
            <>
              <Line>
                <Line
                  key={`radar-line-${i}`}
                  from={zeroPoint}
                  to={points[i]}
                  stroke={silver}
                />
              </Line>
              <text
                x={points[i].x}
                y={points[i].y}
                style={{ fontFamily: "Arial" }}
                dx={getLabelPosX(points[i].x, points)}
                dy={getLabelPosY(points[i].y, points)}
              >
                {data[i].school}
              </text>
            </>
          ))}
          <polygon
            points={polygonPoints.pointString}
            fill={orange}
            fillOpacity={0.3}
            stroke={orange}
            strokeWidth={1}
          />
          {polygonPoints.points.map((point, i) => (
            <>
              <circle
                key={`radar-point-${i}`}
                cx={point.x}
                cy={point.y}
                r={4}
                fill={pumpkin}
              />
              <text x={point.x} y={point.y} dx={"-1rem"} dy={"-0.33rem"}>
                {data[i].distance.toFixed(2)}
              </text>
            </>
          ))}
        </Group>
      </svg>
    </FlexColumn>
  );
}
