import React, { useContext } from "react";
import { Group } from "@visx/group";
import { Cluster, hierarchy } from "@visx/hierarchy";
import {
  HierarchyPointNode,
  HierarchyPointLink,
} from "@visx/hierarchy/lib/types";
import { LinkVertical } from "@visx/shape";
import { LinearGradient } from "@visx/gradient";
import { ThemeContext } from "styled-components";

const background = "#fff";

interface NodeShape {
  name: string;
  children?: NodeShape[];
}

function Node({ node }: { node: HierarchyPointNode<NodeShape> }) {
  const isRoot = node.depth === 0;
  const isParent = !!node.children;

  const themeContext = useContext(ThemeContext);

  if (isRoot) return <RootNode node={node} />;
  if (
    node.parent &&
    node.parent.children &&
    node.parent.children.length > 2 &&
    node.depth === 3
  ) {
    const arrLocation = node.parent.children.findIndex(
      (entry) => entry.data.name === node.data.name
    );

    return (
      <g {...(arrLocation % 2 === 0 ? "" : { transform: "translate(0, 16)" })}>
        {" "}
        <Group top={node.y} left={node.x}>
          <text
            dy=".33em"
            fontSize={14}
            fontFamily="Arial"
            textAnchor="middle"
            style={{ pointerEvents: "none" }}
            fill={
              isParent ? themeContext.primaryColor : themeContext.tertiaryColor
            }
          >
            {node.data.name}
          </text>
        </Group>
      </g>
    );
  }

  return (
    <Group top={node.y} left={node.x}>
      <text
        dy=".33em"
        fontSize={14}
        fontFamily="Arial"
        textAnchor="middle"
        style={{ pointerEvents: "none" }}
        fill={isParent ? themeContext.primaryColor : themeContext.tertiaryColor}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

function RootNode({ node }: { node: HierarchyPointNode<NodeShape> }) {
  const width = 60;
  const height = 30;
  const centerX = -width / 2;
  const centerY = -height / 2;

  const themeContext = useContext(ThemeContext);

  return (
    <Group top={node.y} left={node.x}>
      <rect
        width={width}
        height={height}
        y={centerY}
        x={centerX}
        fill="url('#top')"
      />
      <text
        dy=".33em"
        fontSize={14}
        fontFamily="Arial"
        textAnchor="middle"
        style={{ pointerEvents: "none" }}
        fill={themeContext.primaryColor}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

const defaultMargin = { top: 40, left: 0, right: 0, bottom: 40 };

export type CustomClusterType = {
  surah_num: string;
  ayah_num: string;
  name: string;
  title: string;
  formative: Array<string>;
  early_classical: Array<string>;
  late_classical: Array<string>;
  early_postclassical: Array<string>;
  late_postclassical: Array<string>;
};

export type DendrogramProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  cluster: CustomClusterType;
};

const generateClusterData = (data: CustomClusterType) => {
  const baseObj = {
    name: `${data.surah_num}:${data.ayah_num}`,
    children: [
      {
        name: `${data.name} (${data.title})`,
        children: [] as NodeShape[],
      },
    ],
  };

  if (data.formative.length > 1) {
    baseObj.children[0].children.push({
      name: "formative",
      children: data.formative
        .filter((entry) => entry !== data.name)
        .map((entry) => {
          return {
            name: entry,
          };
        }),
    });
  }

  if (data.early_classical.length > 0) {
    baseObj.children[0].children.push({
      name: "early classical",
      children: data.early_classical.map((entry) => {
        return {
          name: entry,
        };
      }),
    });
  }

  if (data.late_classical.length > 0) {
    baseObj.children[0].children.push({
      name: "late classical",
      children: data.late_classical.map((entry) => {
        return {
          name: entry,
        };
      }),
    });
  }

  if (data.early_postclassical.length > 0) {
    baseObj.children[0].children.push({
      name: "early postclassical",
      children: data.early_postclassical.map((entry) => {
        return {
          name: entry,
        };
      }),
    });
  }

  if (data.late_postclassical.length > 0) {
    baseObj.children[0].children.push({
      name: "late postclassical",
      children: data.late_postclassical.map((entry) => {
        return {
          name: entry,
        };
      }),
    });
  }

  return baseObj;
};

export default function OriginTree({
  width,
  height,
  margin = defaultMargin,
  cluster,
}: DendrogramProps) {
  const data = hierarchy<NodeShape>(generateClusterData(cluster));

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const themeContext = useContext(ThemeContext);

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <LinearGradient
        id="top"
        from={themeContext.secondaryColor}
        to={themeContext.secondaryColor}
      />
      <rect width={width} height={height} fill={background} />
      <Cluster<NodeShape> root={data} size={[xMax, yMax]}>
        {(cluster) => (
          <Group top={margin.top} left={margin.left}>
            {cluster.links().map((link, i) => (
              <LinkVertical<
                HierarchyPointLink<NodeShape>,
                HierarchyPointNode<NodeShape>
              >
                key={`cluster-link-${i}`}
                data={link}
                stroke={themeContext.secondaryColor}
                strokeWidth="2"
                strokeOpacity={1}
                fill="none"
              />
            ))}
            {cluster.descendants().map((node, i) => (
              <Node key={`cluster-node-${i}`} node={node} />
            ))}
          </Group>
        )}
      </Cluster>
    </svg>
  );
}
