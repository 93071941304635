import React, { useContext, useEffect, useState, useRef } from "react";
import styled, { ThemeContext } from "styled-components";
import axios from "axios";

import { Sigma, RandomizeNodePositions, EdgeShapes } from "react-sigma";
import ForceLink from "react-sigma/lib/ForceLink";

import { H1 } from "./Typography";

import { SubInstruction } from "./Stats";

import SigmaControls from "./SigmaControls";
import { Legend, LegendEntry } from "./SigmaNetwork";
import ThreeColumns from "./ThreeColumns";
import { FlexColumn } from "./Flex";
import Loader from "./Loader";

const DiamondShape = styled.div`
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-bottom-color: #ffd700;
  position: relative;
  top: -1rem;

  &:after {
    content: "";
    position: absolute;
    left: -1rem;
    top: 1rem;
    width: 0;
    height: 0;
    border: 1rem solid transparent;
    border-top-color: #ffd700;
  }
`;
export const VisualizationToolTip = styled.section`
  background: #fff;
  padding: 1rem 2rem;
  line-height: 1.5;
  max-width: 40rem;
  min-width: 30rem;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 25%) inset, 0 5px 10px 5px rgb(0 0 0 / 25%);
  z-index: 2;
  border: solid 1vmin #eee;
  box-sizing: border-box;
`;
const CitationNumber = styled.p`
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  padding: 0.25rem;
  font-family: "Lobster";
  font-size: 1.2rem;
  color: #5a6078;
`;

const Translation = styled.p`
  font-style: italic;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  ${H1} {
    margin: 2rem 0;
  }
`;

const HolyQuran = styled.p`
  font-family: "mequran";
  font-size: 1.4rem;
  direction: rtl;
  line-height: 2;
  word-spacing: 0.5rem;
  margin-bottom: 1rem;
`;

const Label = styled.span`
  border-radius: 5px;
  font-family: "Arial";
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
`;

const InfoIconSvg = styled.svg``;

export const GraphHelp = styled.p`
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  margin: 1rem;
  font-family: "Open Sans";
  font-size: 0.9rem;
  line-height: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  b {
    font-size: 1rem;
  }

  i {
    color: ${(props) => props.theme.pentiaryColor};
    font-weight: 700;
    font-style: italic;
  }

  span {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
`;

export const InfoIcon = ({ color }) => (
  <InfoIconSvg
    viewBox="0 0 20 20"
    width="16"
    height="16"
    style={{ margin: "0 1rem 0 0" }}
  >
    <path
      strokeWidth="1"
      fill={color}
      d="M10 0.4c-5.303 0-9.601 4.298-9.601 9.6 0 5.303 4.298 9.601 9.601 9.601 5.301 0 9.6-4.298 9.6-9.601s-4.299-9.6-9.6-9.6zM10.896 3.866c0.936 0 1.211 0.543 1.211 1.164 0 0.775-0.62 1.492-1.679 1.492-0.886 0-1.308-0.445-1.282-1.182 0-0.621 0.519-1.474 1.75-1.474zM8.498 15.75c-0.64 0-1.107-0.389-0.66-2.094l0.733-3.025c0.127-0.484 0.148-0.678 0-0.678-0.191 0-1.022 0.334-1.512 0.664l-0.319-0.523c1.555-1.299 3.343-2.061 4.108-2.061 0.64 0 0.746 0.756 0.427 1.92l-0.84 3.18c-0.149 0.562-0.085 0.756 0.064 0.756 0.192 0 0.82-0.232 1.438-0.719l0.362 0.486c-1.513 1.512-3.162 2.094-3.801 2.094z"
    ></path>
  </InfoIconSvg>
);

const Cross = () => (
  <svg viewBox="0 0 32 32" width="20" height="20">
    <path
      fill="#eee"
      d="M30 24.398l-8.406-8.398 8.406-8.398-5.602-5.602-8.398 8.402-8.402-8.402-5.598 5.602 8.398 8.398-8.398 8.398 5.598 5.602 8.402-8.402 8.398 8.402z"
    ></path>
  </svg>
);

const CloseButtonContainer = styled.div`
  position: absolute;
  right: -3.3rem;
  top: 0;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 25%) inset, 0 2px 4px 2px rgb(0 0 0 / 25%);

  button {
    background-color: ${(props) => props.theme.primaryColor};
    border: 0;
    padding: 0.8rem;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Cluster = (props) => {
  const refContainer = useRef(null);
  const [data, setData] = useState({
    nodes: [],
    edges: [],
  });

  const [showToolTip, setShowTooltip] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    console.log("Surah num is", props.surahNum);

    axios.get(`/api/cluster/${props.surahNum}/${props.ayahNum}`).then((res) => {
      console.log(res.data);
      setData(res.data);
      setIsLoading(false);
    });
  }, [props.surahNum, props.ayahNum]);

  if (data.nodes.length === 0) {
    return null;
  }

  const isRootVerse = (surahNum, ayahNum, label) => {
    return surahNum === label.split(":")[0] && ayahNum === label.split(":")[1];
  };

  if (data.edges.length === 0) {
    return null;
  }

  return (
    <ThreeColumns style={{ overflow: "visible" }}>
      {!isLoading ? (
        <FlexColumn style={{ width: "100%" }}>
          <H1 style={{ margin: "3rem 0 0" }}>Intertextuality</H1>
          <SubInstruction style={{ textAlign: "center", margin: "1rem 0 0" }}>
            (experimental co-citations graph based on same corpus page
            discoveries)
          </SubInstruction>
          <GraphHelp>
            <p style={{ display: "flex", width: "100%" }}>
              <b>Instructions: </b>
            </p>
            <span>
              <InfoIcon color={themeContext.pentiaryColor} />
              <i>hovering on a node &nbsp;</i> displays the source of a co-cited
              verse
            </span>
            <span>
              <InfoIcon color={themeContext.pentiaryColor} />
              <i>clicking &nbsp;</i> on a node will display results from the
              corpus in which the main verse and the clicked verse are co-cited
            </span>
          </GraphHelp>
          <Wrapper ref={refContainer}>
            <Sigma
              graph={data}
              renderer="canvas"
              onClickNode={(e) => {
                setShowTooltip(undefined);

                if (document.querySelector("#corpus-header")) {
                  const y =
                    document
                      .querySelector("#corpus-header")
                      .getBoundingClientRect().top + window.pageYOffset;

                  window.scrollTo({ top: y, behavior: "smooth" });
                }

                props.setLinkedVerse(
                  e.data.node.label,
                  e.data.node.attributes.pages
                );
              }}
              onClickStage={() => setShowTooltip(undefined)}
              onOverNode={(e) => {
                if (e.data.node.id.indexOf("n") === -1) {
                  return;
                }

                setShowTooltip(e.data);
              }}
              settings={{
                clone: false,
                batchEdgesDrawing: true,
                defaultHoverLabelBGColor: "#002147",
                defaultLabelBGColor: "#ddd",
                activeFontStyle: "bold",
                defaultLabelColor: "#000",
                labelThreshold: 1,
                defaultLabelHoverColor: "#fff",
                fontStyle: "bold",
                hoverFontStyle: "bold",
                defaultLabelSize: 12,
                enableEdgeHovering: false,
                animationsTime: 400,
                defaultNodeColor: "#ffd700",
                defaultEdgeColor: "#fed7bf",
                edgeColor: "default",
                mouseWheelEnabled: false,
                minNodeSize: 4,
                maxNodeSize: 10,
              }}
              style={{
                height: "50rem",
                maxWidth: "inherit",
              }}
            >
              <RandomizeNodePositions>
                <EdgeShapes default="curvedArrow" />
                <ForceLink
                  background
                  easing="cubicInOut"
                  gravity={2}
                  linLogMode
                  randomize="locally"
                  timeout={2000}
                  worker
                />
              </RandomizeNodePositions>
              <SigmaControls />
            </Sigma>
            <Legend>
              <LegendEntry key={`legend-entry`}>
                <DiamondShape />
                <p style={{ marginLeft: "1rem" }}>
                  3 or more unique pages co-cited
                </p>
              </LegendEntry>
            </Legend>
            {showToolTip ? (
              <VisualizationToolTip
                style={{
                  position: "absolute",
                  top: `${
                    showToolTip.captor.clientY +
                    8 -
                    refContainer.current.getBoundingClientRect().top
                  }px`,
                  left: `${
                    showToolTip.captor.clientX -
                    8 -
                    refContainer.current.getBoundingClientRect().left
                  }px`,
                }}
              >
                <CloseButtonContainer>
                  <button
                    style={{ margin: 0 }}
                    onClick={() => setShowTooltip(undefined)}
                  >
                    <Cross />
                  </button>
                </CloseButtonContainer>
                <CitationNumber>
                  {!isRootVerse(
                    props.surahNum,
                    props.ayahNum,
                    showToolTip.node.label
                  ) ? (
                    <span>
                      Cited together on{" "}
                      {showToolTip.node.attributes.pages.length} pages.
                    </span>
                  ) : (
                    <span>
                      Cited: {showToolTip.node.attributes.count} times.
                    </span>
                  )}
                </CitationNumber>
                <section>
                  <HolyQuran>{showToolTip.node.attributes.body}</HolyQuran>
                  <Translation>
                    {showToolTip.node.attributes.translation}
                  </Translation>
                </section>
                <p style={{ margin: "0 0 1rem" }}>
                  {showToolTip.node.attributes.schools.map((school) => (
                    <Label
                      key={`hover-label-${school}`}
                      style={{
                        backgroundColor:
                          themeContext.components.legend.schools[school],
                      }}
                    >
                      {school}
                    </Label>
                  ))}
                </p>
              </VisualizationToolTip>
            ) : null}
          </Wrapper>
        </FlexColumn>
      ) : (
        <Loader text="Loading intertextuality data" />
      )}
    </ThreeColumns>
  );
};

export default Cluster;
