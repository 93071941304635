export type SchoolName =
  | "Ḥanafī"
  | "Ḥanbalī"
  | "Jaʿfarī"
  | "Mālikī"
  | "Shāfiʿī";

export const schoolNames: SchoolName[] = [
  "Jaʿfarī",
  "Mālikī",
  "Shāfiʿī",
  "Ḥanafī",
  "Ḥanbalī",
];
