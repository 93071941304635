import React from "react";
import styled from "styled-components";

const LoaderBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 12;
  top: 0;
  left: 0;
`;
const LoaderContainer = styled.div`
  background-color: #fff;
  padding: 2rem 3rem;
  text-align: center;
  margin: 1rem;

  p {
    margin-top: 0.5rem;
  }
`;

export const LoaderText = styled.p`
  font-family: "Lobster";
  font-size: 1.5rem;
  color: ${(props) => props.theme.components.loader.textColor};
`;

const CssLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }

  div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${(props) => props.theme.components.loader.textColor}};
    margin: -4px 0 0 -4px;
  }

  div:nth-child(1) {
    animation-delay: -0.036s;
  }

  div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }

  div:nth-child(2) {
    animation-delay: -0.072s;
  }

  div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }

  div:nth-child(3) {
    animation-delay: -0.108s;
  }

  div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }

  div:nth-child(4) {
    animation-delay: -0.144s;
  }

  div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }

  div:nth-child(5) {
    animation-delay: -0.18s;
  }

  div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }

  div:nth-child(6) {
    animation-delay: -0.216s;
  }

  div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }

  div:nth-child(7) {
    animation-delay: -0.252s;
  }

  div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }

  div:nth-child(8) {
    animation-delay: -0.288s;
  }

  div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ text, style }: { text: string; style?: any }) => {
  return (
    <LoaderBackground style={style}>
      <LoaderContainer>
        <CssLoader>
          {Array.from({ length: 8 }, (_, idx) => (
            <div key={`loader-${Date.now()}-${idx}`}></div>
          ))}
        </CssLoader>
        <LoaderText>{text}</LoaderText>
      </LoaderContainer>
    </LoaderBackground>
  );
};

export default Loader;
