import styled from "styled-components";

export const InputGroupContainer = styled.div`
  margin: 1rem 1rem 0.5rem;
  display: flex;
  align-items: center;

  button {
    margin-bottom: 0.5rem;
  }
`;

export const ReactSelectContainer = styled(InputGroupContainer)`
  width: 100%;
`;

export const InputSeparator = styled.div`
  border-right: 1px solid ${(props) => props.theme.primaryColor};
  height: 1.6rem;
  margin: 1rem 0 0.5rem;
`;

export const InputLabel = styled.label`
  margin: 0 0.5rem 0 0;
  font-style: italic;
`;

export const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.primaryColor};
  max-width: 10rem;
`;

export const TextInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.primaryColor};
`;
