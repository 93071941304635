import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import Sticky from "react-sticky-el";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

import QuranDisplay from "./QuranDisplay";
import VerseMetaInfo from "./VerseMetaInfo";
import { H1 } from "./Typography";
import Reader from "./Reader";
import PaginationContainer from "./PaginationContainer";
import debounce from "lodash/debounce";
import Loader from "./Loader";

const PageTitle = styled(H1)`
  margin: 2rem 0;
`;

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;

  ${PageTitle} {
    margin-bottom: 1rem;
  }
`;

export interface IFilters {
  link: string[];
  authors: string[];
  schools: string[];
  periods: string[];
}

const useFetchCorpusResults = (): [
  JSX.Element | null,
  JSX.Element | null,
  () => JSX.Element | null,
  JSX.Element | null,
  { surah_num: string; ayah_num: string },
  Dispatch<SetStateAction<{ surah_num: string; ayah_num: string }>>,
  { link: string[] },
  Dispatch<SetStateAction<any>>,
  {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    nextPage: number;
    page: number;
    pagingCounter: number;
    prevPage: number;
    totalDocs: number;
    totalPages: number;
  }
] => {
  const [verse, setVerse] = useState({
    surah_num: "",
    ayah_num: "",
  });

  const [isFetching, setIsFetching] = useState(false);

  const [filters, setFilters] = useState({} as IFilters);

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 0,
    pagingCounter: 0,
    prevPage: 0,
    totalDocs: 0,
    totalPages: 0,
  });
  const [relevantPages, setRelevantPages] = useState([] as any);

  useEffect(() => {
    if (verse.surah_num.length > 0 && verse.ayah_num.length > 0) {
      axios
        .get(`/api/verse/${verse.surah_num}/${verse.ayah_num}`, {
          params: { page: pagination.page + 1, ...filters },
        })
        .then((response) => {
          setRelevantPages(response.data.docs);
          setIsFetching(false);
          setPagination({
            hasNextPage: response.data.hasNextPage,
            hasPrevPage: response.data.hasPrevPage,
            limit: response.data.limit,
            nextPage: response.data.nextPage,
            page: response.data.page - 1,
            pagingCounter: response.data.pagingCounter,
            prevPage: response.data.prevPage,
            totalDocs: response.data.totalDocs,
            totalPages: response.data.totalPages,
          });
        });
    }
  }, [pagination.page, verse.surah_num, verse.ayah_num, filters]);

  if (verse.surah_num.length === 0 && verse.ayah_num.length === 0) {
    return [
      null,
      null,
      () => null,
      null,
      { surah_num: "", ayah_num: "" },
      setVerse,
      {} as IFilters,
      setFilters,
      {
        hasNextPage: false,
        hasPrevPage: false,
        limit: 0,
        nextPage: 0,
        page: 0,
        pagingCounter: 0,
        prevPage: 0,
        totalDocs: 0,
        totalPages: 0,
      },
    ];
  }

  const returnRelevantPages = () => {
    if (relevantPages.length === 0 && !isFetching) {
      return null;
    }

    if (isFetching) {
      return <Loader text="Loading corpus page" />;
    }

    return (
      <FlexColumnDiv style={{ paddingBottom: "1rem" }}>
        {relevantPages.map((p: any, index: any) => {
          return (
            <Reader
              key={`${p._id}-${index}-${new Date().getTime()}`}
              sourceId={p.source}
              startPage={p.pp}
              tag={p.tag}
              tagNr={p.tag_nr}
              surah={verse.surah_num}
              ayah={verse.ayah_num}
            />
          );
        })}
      </FlexColumnDiv>
    );
  };

  return [
    <QuranDisplay surah={verse.surah_num} ayah={verse.ayah_num} />,
    <VerseMetaInfo
      pages={relevantPages}
      pagination={pagination}
      surah={verse.surah_num}
      ayah={verse.ayah_num}
    />,
    returnRelevantPages,
    pagination.totalPages > 1 ? (
      <Sticky mode={"bottom"} stickyClassName={"sticky-pagination"}>
        <PaginationContainer>
          <h2>Search result pages:</h2>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={pagination.totalPages}
            forcePage={pagination.page}
            marginPagesDisplayed={0}
            pageRangeDisplayed={5}
            onPageChange={debounce((data) => {
              if (document.querySelector("#corpus-header")) {
                const element = document.querySelector(
                  "#corpus-header"
                ) as HTMLElement;

                const y =
                  element.getBoundingClientRect().top + window.pageYOffset;

                window.scrollTo({ top: y, behavior: "smooth" });
              }

              setIsFetching(true);
              setPagination(
                Object.assign({}, pagination, { page: data.selected })
              );
            }, 500)}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </PaginationContainer>
      </Sticky>
    ) : null,
    verse,
    setVerse,
    filters,
    setFilters,
    pagination,
  ];
};

export default useFetchCorpusResults;
