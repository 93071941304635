import React from "react";
import styled from "styled-components";
import ThreeColumns from "./ThreeColumns";
import { H1, H2 } from "./Typography";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  H1 {
    margin-top: 2rem;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const ProfileImage = styled.img`
  width: 7rem;
  height: 8rem;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  min-height: 16rem;

  h2 {
    margin: 0 0 1rem 0;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
`;

const ProfileText = styled.p`
  max-width: 40rem;
  line-height: 1.5;

  a {
    display: flex;
    margin-top: 1rem;
  }

  ul {
    list-style-type: circle;
  }
  li {
    margin: 1rem 2rem;
  }
`;

const Profile = () => {
  return (
    <ThreeColumns>
      <Container>
        <H1>About</H1>
        <H2 style={{ marginTop: "2rem" }}>Project description</H2>
        <ProfileContainer>
          <ProfileInfo style={{ minHeight: "auto" }}>
            <FlexColumn style={{ marginLeft: 0 }}>
              <ProfileText>
                Footprinter is an online application that aids in the analysis
                and tracking of the Qur'an's footprint in fifty-five works of
                substantive law. These fifty-five works have been gathered from
                the following online resources: <i>rafed.net</i>,{" "}
                <i>shamela.ws</i>, <i>islamweb.net</i>, and <i>masaha.org</i>.
                Footprinter's chief features are:
                <ul>
                  <li>
                    Footprint analysis of the Qur'an in fifty-five works of
                    substantive law within a configurable network graph.
                  </li>
                  <li>
                    The ability to read the exact corpus page on which a Qur'an
                    verse has been discovered.
                  </li>
                  <li>
                    A robust search engine that allows for users to inquire
                    where and how many times a Qur'an verse is discovered in
                    fifty-five works of substantial law.
                  </li>
                  <li>
                    An intertextuality graph that shows which verses are
                    co-cited with a selected Qur'an verse.
                  </li>
                  <li>
                    A historicity table that shows potential continuity and
                    discontinuity in Qur'an reliance within a legal school.
                  </li>
                  <li>
                    Extended statistics and rankings involving Qur'an citations.
                  </li>
                </ul>
              </ProfileText>
            </FlexColumn>
          </ProfileInfo>
        </ProfileContainer>
        <H2>Contributors</H2>
        <ProfileContainer>
          <ProfileInfo>
            <ProfileImage src="/images/celik.jpg" />
            <FlexColumn>
              <h2 style={{ lineHeight: 1.8 }}>
                Platform role(s): data engineer, architect, and front-end and
                back-end developer
              </h2>
              <ProfileText>
                Dr. Yusuf Celik (PhD University of Edinburgh, 2020) is an
                assistant professor Digital Humanities & Islam at the Vrije
                University Amsterdam. His research is on digital humanities,
                Qur'anic hermeneutics, and history of ideas. Dr. Yusuf Celik has
                also been active for years in the field of software engineering.
                As an independent contractor he has worked for different high
                profile clients in the capacity of lead developer, consultant,
                code coach, and Scrum master.
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://research.vu.nl/en/persons/yusuf-%C3%A7elik"
                >
                  Read more
                </a>
                <a href="mailto:y.celik@vu.nl">Email</a>
              </ProfileText>
            </FlexColumn>
          </ProfileInfo>
          <ProfileInfo>
            <ProfileImage src="/images/lange.jpg" />
            <FlexColumn>
              <h2 style={{ lineHeight: 1.8 }}>
                Platform role(s): corpus design, project supervisor
              </h2>
              <ProfileText>
                Prof. dr. Christian Lange (PhD Harvard, 2006) holds the Chair of
                Arabic and Islamic Studies at the University of Utrecht. His
                research is on Islamic intellectual and cultural history,
                particularly in the areas of Islamic eschatology, Islamic law
                and legal theory, and Islamic mysticism. His work on the
                Footprinter was supported by the ERC Consolidator Grant 'The
                senses of Islam (SENSIS)’ (2017-2023, project no. 724951).
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.uu.nl/staff/CRLange/0"
                >
                  Read more
                </a>
                <a href="mailto:C.R.Lange@uu.nl">Email</a>
              </ProfileText>
            </FlexColumn>
          </ProfileInfo>
        </ProfileContainer>
      </Container>
    </ThreeColumns>
  );
};

export default Profile;
